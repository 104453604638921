import axios from "axios";

import config from "../config";
import { encodeQueryData } from "./utils";

const ENDPOINT = config.apiEndpoint;

// Common
const getSyncStatus = (params) => {
  let paramsStr = "";
  if (params) {
    paramsStr = `?${encodeQueryData(params)}`;
  }
  const url = `${ENDPOINT}/auth/sync/shopify/syncStatus${paramsStr}`;
  return axios({
    url,
    method: "GET",
    withCredentials: true,
  })
};

const cancelSync = (syncName) => {
  const url = `${ENDPOINT}/auth/sync/shopify/cancelSync`;
  return axios({
    url,
    method: "POST",
    data: {syncName,},
    withCredentials: true,
  })
};

const getAllSyncStatus = () => {
  return getSyncStatus();
}
// End common

// Sync all
const syncAll = () => {
  const url = `${ENDPOINT}/auth/sync/shopify/syncAll`;
  return axios({
    url,
    method: "post",
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
};

const getSyncAllStatus = () => {
  return getSyncStatus({"syncAll": true});
}

const cancelSyncAll = () => {
  return cancelSync("syncAll");
}
// End sync all

// Sync product positions
const syncProdPosMColl = () => {
  const url = `${ENDPOINT}/auth/sync/shopify/syncProdPosMColl`;
  return axios({
    url,
    method: "post",
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
};

const getSyncProdPosMCollStatus = () => {
  return getSyncStatus({"syncProdPosMColl": true});
}

const cancelSyncProPosMColl = () => {
  return cancelSync("syncProdPosMColl");
}
// End sync all


// Sync product positions
const syncProductReviews = () => {
  const url = `${ENDPOINT}/auth/sync/shopify/syncProductReviews`;
  return axios({
    url,
    method: "post",
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
};

const getSyncProductReviewsStatus = () => {
  return getSyncStatus({"syncProductReviews": true});
}

const cancelSyncProductReviews = () => {
  return cancelSync("syncProductReviews");
}
// End sync all



const syncAPI = {
  getAllSyncStatus,

  syncAll,
  getSyncAllStatus,
  cancelSyncAll,

  syncProdPosMColl,
  getSyncProdPosMCollStatus,
  cancelSyncProPosMColl,

  syncProductReviews,
  getSyncProductReviewsStatus,
  cancelSyncProductReviews,
};

export default syncAPI;

