import axios from "axios";

import config from "../config";

const ENDPOINT = config.apiEndpoint;

const getAll = () => {
  const url = `${ENDPOINT}/auth/defaultSearchFields`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

const updateOne = (item) => {
  const url = `${ENDPOINT}/auth/defaultSearchFields/${item.id}`;
  const {
    name, weight, searchForProducts, buildSearchSuggestion, enable
  } = item;
  return axios({
    url,
    method: "put",
    data: {
      name,
      weight,
      searchForProducts,
      buildSearchSuggestion,
      enable,
    },
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

const defaultSearchFieldAPI = {
  getAll,
  updateOne,
};

export default defaultSearchFieldAPI;
