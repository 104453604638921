import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {updateSelectedItems, updateSelectedItem, updateItemEnableStatus} from "./utils";

const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  searchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
  firstGetAllLoading: false,
};

const INIT_COMMON = {
  selectedItemCount: 0,
  error: '',
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {},
};

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "synonyms",
  initialState: INIT_STATE,
  reducers: {
    // Create a synonyms
    createASynonyms() {},

    createASynonymsSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
      }
    },

    showCreateASynonymsModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        showCreateAItemModal: true,
      }
    },

    hideCreateASynonymsModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all synonyms
    getAllSynonymss: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
          params.search = params.search || '';
        }
        return {
          payload: params
        }
      }
    },

    getAllSynonymssSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all synonymss
        const items = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          items,
          totalCurrentItems: total,
          selectedItemCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalItems = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}}
      }
    },

    setSynonymsSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setDidFirstGetAllSynonymsRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    setFirstGetAllSynonymsLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    // Update a synonyms
    updateASynonyms: {
      reducer(state) {
        return state;
      },
      prepare(synonyms, metadata) {
        return {payload: {synonyms, metadata}};
      }
    },

    updateASynonymsSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    updateStatusASynonyms() {},

    updateStatusASynonymsSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showEditASynonymsModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditASynonymsModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Delete a item
    deleteASynonyms() {},

    // Delete many
    deleteManySynonymss() {},

    deleteManySynonymssSuccess(state) {
      state.selectedItemCount = 0;
    },

    // Common
    updateSelectedSynonyms(state, action) {
      state.selectedItemCount = updateSelectedItem(state.items, action.payload);
    },

    updateAllSynonymssToSelected(state) {
      updateSelectedItems(state.items, true);
      state.selectedItemCount = state.items.length;
    },

    updateAllSynonymssToUnSelected(state) {
      updateSelectedItems(state.items, false);
      state.selectedItemCount = 0;
    },
  }
});

export const {
  createASynonyms,
  createASynonymsSuccess,
  showCreateASynonymsModal,
  hideCreateASynonymsModal,
  getAllSynonymss,
  getAllSynonymssSuccess,
  setFirstGetAllSynonymsLoading,
  setSynonymsSearchTerm,
  setDidFirstGetAllSynonymsRequest,
  updateASynonyms,
  updateASynonymsSuccess,
  updateStatusASynonyms,
  updateStatusASynonymsSuccess,
  showEditASynonymsModal,
  hideEditASynonymsModal,
  deleteASynonyms,
  deleteManySynonymss,
  deleteManySynonymssSuccess,
  updateSelectedSynonyms,
  updateAllSynonymssToSelected,
  updateAllSynonymssToUnSelected,
} = slice.actions;

export default slice.reducer;
