import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import {
  authenticateFailed,
  checkFirstIndexing,
  checkFirstIndexingSuccess,
  doFirstSetupWidget,
  doFirstSetupWidgetSuccess,
  firstSetupDone,
  firstSetupDoneSuccess,
  getMe,
  getMeSuccess,
  showAuthLoader,
  showAuthMessage,
  userSignIn,
  userSignInSuccess,
  userTokenSignIn,
  userTokenSignInSuccess
} from "../../appRedux/slices/Auth";

import authApi from "../../services/auth";
import {
  fetchError,
  fetchItem2Error,
  fetchItem2Start,
  fetchItem2Success,
  fetchItemError,
  fetchItemStart,
  fetchItemSuccess,
} from "../slices/Common";
import {hideMfSfSyncBanner, showMfSfSyncBanner} from "../slices/MetafieldSearchField";
import {hideVMfSfSyncBanner, showVMfSfSyncBanner} from "../slices/VariantMetafieldSearchField";
import {hideDfSfSyncBanner, showDfSfSyncBanner} from "../slices/DefaultSearchField";
import themeAPI from "../../services/theme";
import { savePublicToken } from "../../util/publicToken";

// Sign in with token
function* signInUserWithToken({payload}) {
  const token = payload;
  try {
    const resp = yield call(authApi.signInWithToken, token);
    const data = resp.data.user;
    yield put(userTokenSignInSuccess(data))

    // Request to /me to get User info
    yield put(getMe());

    // hide, show sync banner
    if (data?.settings?.searchFields) {
      const {displayDefaultSyncBanner, displayMetafieldSyncBanner, displayVariantMetafieldSyncBanner} = data.settings.searchFields;
      if (displayMetafieldSyncBanner) {
        yield put(showMfSfSyncBanner());
      }
      if (displayVariantMetafieldSyncBanner) {
        yield put(showVMfSfSyncBanner())
      }
      if (displayDefaultSyncBanner) {
        yield put(showDfSfSyncBanner());
      }
    }
  } catch (error) {
    yield put(authenticateFailed());
    yield put(showAuthMessage(error))
  }
}

function* signInWithToken() {
  yield takeEvery(userTokenSignIn, signInUserWithToken);
}

// Sign in with email, password
function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const {data} = yield call(authApi.signIn, email, password);
    yield put(userSignInSuccess(data.user));
  } catch (error) {
    yield put(authenticateFailed());
    yield put(showAuthMessage(error));
  }
}

function* signInUser() {
  yield takeEvery(userSignIn, signInUserWithEmailPassword);
}

// Get me
function* getMeRequest({payload}) {
  const {showLoading, params} = payload;
  try {
    if (showLoading) {
      yield put(showAuthLoader());
    }
    const {data} = yield call(authApi.getMe, params);
    savePublicToken(data?.publicToken);
    yield put(getMeSuccess(data));

    // hide, show sync banner
    if (data.settings.searchFields) {
      const {displayDefaultSyncBanner, displayMetafieldSyncBanner, displayVariantMetafieldSyncBanner} = data.settings.searchFields;
      if (displayMetafieldSyncBanner) {
        yield put(showMfSfSyncBanner());
      }
      if (displayVariantMetafieldSyncBanner) {
        yield put(showVMfSfSyncBanner())
      }
      if (displayDefaultSyncBanner) {
        yield put(showDfSfSyncBanner());
      }
    }
  } catch (error) {
    yield put(authenticateFailed());
    yield put(showAuthMessage(error));
  }
}

function* getMeSaga() {
  yield takeEvery(getMe, getMeRequest);
}

// Check first indexing
function* checkFirstIndexingRequest() {
  try {
    const {data} = yield call(authApi.firstIndexing);
    yield put(checkFirstIndexingSuccess(data.firstIndexing));
  } catch (error) {
    yield put(fetchError());
  }
}

function* checkFirstIndexingSaga() {
  yield takeEvery(checkFirstIndexing, checkFirstIndexingRequest);
}

// Do first setup widget
function* doFirstSetupWidgetRequest({payload}) {
  try {
    yield put(fetchItem2Start());
    yield call(themeAPI.setup, payload);
    yield put(doFirstSetupWidgetSuccess());
    yield put(fetchItem2Success());
  } catch (error) {
    yield put(fetchItem2Error());
  }
}

function* doFirstSetupWidgetSaga() {
  yield takeEvery(doFirstSetupWidget, doFirstSetupWidgetRequest);
}

// First setup done
function* firstSetupDoneRequest() {
  try {
    yield put(fetchItemStart());
    yield call(authApi.firstSetupDone);
    yield put(firstSetupDoneSuccess());
    yield put(fetchItemSuccess());
  } catch (error) {
    yield put(fetchItemError());
  }
}

function* firstSetupDoneSaga() {
  yield takeEvery(firstSetupDone, firstSetupDoneRequest);

}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signInWithToken),
    fork(getMeSaga),
    fork(checkFirstIndexingSaga),
    fork(doFirstSetupWidgetSaga),
    fork(firstSetupDoneSaga),
  ]);
}
