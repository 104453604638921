import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchItemError,
  fetchItemStart,
  fetchItemSuccess,
} from "../slices/Common";
import shopifyThemeAPI from "../../services/shopifyTheme";
import {getAllShopifyThemes, getAllShopifyThemesSuccess} from "../slices/ShopifyTheme";

function* getAllShopifyThemesRequest() {
  try {
    yield put(fetchItemStart());
    const {data} = yield call(shopifyThemeAPI.getAllShopifyThemes);
    yield put(getAllShopifyThemesSuccess(data));
    yield put(fetchItemSuccess());
  } catch (error) {
    yield put(fetchItemError());
  }
}

function* getAllShopifyThemesSaga() {
  yield takeEvery(getAllShopifyThemes, getAllShopifyThemesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getAllShopifyThemesSaga),
  ]);
}
