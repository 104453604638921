import { useHistory } from "react-router-dom";

const useGoToPage = () => {
  const history = useHistory();
  const goToPage = path => {
    history.push(path);
  };

  return { goToPage };
};

export default useGoToPage;
