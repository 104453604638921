import {useHistory} from "react-router-dom";

const usePricing = ({reload = false} = {}) => {
  const history = useHistory();
  const goToPricingPage = () => {
    if (reload) {
      window.location.href = "/pricing";
    } else {
      history.push("/pricing");
    }
  }
  return {goToPricingPage}
}

export default usePricing;
