import {createSlice} from "@reduxjs/toolkit";
import {SYNC_STATUS_COMPLETED, SYNC_STATUS_RUNNING} from "../../constants/Sync";

const INIT_STATE = {
  loader: true,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  // authUser: JSON.parse(localStorage.getItem('user')),
  authUser: {
    id: null,
    store: {},
    settings: {
      usageStatus: {
        filter: false,
        instantSearch: false
      }
    }
  },
  isAuthenticated: false,
};

const slice = createSlice({
  name: "auth",
  initialState: INIT_STATE,
  reducers: {
    authenticateFailed(state) {
      state.isAuthenticated = false;
    },

    // Sign in with token
    userTokenSignIn() {
    },

    userTokenSignInSuccess(state, action) {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        isAuthenticated: true,
      }
    },

    // Sign in with email, password
    userSignIn() {
    },

    userSignInSuccess(state, action) {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        isAuthenticated: true,
      }
    },

    // Get Me
    getMe: {
      reducer(state) {
        return state;
      },
      prepare(showLoading = true, params) {
        return {payload: {showLoading, params}};
      }
    },

    getMeSuccess(state, action) {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        isAuthenticated: true
      }
    },

    // Set usage status
    setUsageStatus(state, action) {
      state.authUser.settings.usageStatus = {...state.authUser.settings.usageStatus, ...action.payload};
    },

    // Start sync shopify
    startSyncShopify() {
    },

    startSyncShopifySuccess(state) {
      state.syncStatus = SYNC_STATUS_RUNNING;
    },

    // Get sync status
    getSyncStatus() {
    },

    getSyncStatusSuccess(state, action) {
      state.syncStatus = action.payload;
    },

    getSyncStatusError(state, action) {
      state.syncStatus = SYNC_STATUS_COMPLETED;
    },

    // Check first indexing
    checkFirstIndexing() {
    },

    checkFirstIndexingSuccess(state, action) {
      state.authUser.store.firstIndexing = action.payload;
    },

    doFirstSetupWidget() {
    },

    doFirstSetupWidgetSuccess(state) {
      state.authUser.store.firstSetupWidget = true;
    },

    firstSetupDone() {
    },

    firstSetupDoneSuccess(state) {
      state.authUser.store.firstSetup = true;
    },

    setInitUrl(state, action) {
      state.initURL = action.payload;
    },

    showAuthMessage(state, action) {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    },

    hideMessage(state) {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    },

    showAuthLoader(state) {
      state.loader = true;
    }
  },
});

export const {
  authenticateFailed,
  userTokenSignIn,
  userTokenSignInSuccess,
  userSignIn,
  userSignInSuccess,
  getMe,
  getMeSuccess,
  setUsageStatus,
  startSyncShopify,
  startSyncShopifySuccess,
  getSyncStatus,
  getSyncStatusSuccess,
  getSyncStatusError,
  checkFirstIndexing,
  checkFirstIndexingSuccess,
  doFirstSetupWidget,
  doFirstSetupWidgetSuccess,
  firstSetupDone,
  firstSetupDoneSuccess,
  setInitUrl,
  showAuthMessage,
  hideMessage,
  showAuthLoader
} = slice.actions;

export default slice.reducer;
