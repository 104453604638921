import React, { lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// import Search from "./Search";
// import FilterGroups from "./FilterGroups";
// import Customization from "./Customization";
import { PATH_CUSTOMIZATION } from "./Customization/constants";
// import Analytics from "./Analytics";
import usePricing from "./hooks/usePricing";
import Banner from "../components/Banner";
import useSubPlan from "../hooks/useSubPlan";
// import Settings from "./Settings";
// import Faqs from "./Faqs";
import LazyLoad from "../components/LazyLoad";
// import FilterOptionGroups from "./FilterOptionGroups";

const Home = lazy(() => import("./Home"));
const ThemeSetup = lazy(() => import("./ThemeSetup"));
const Integration = lazy(() => import("./Integration"));
const ProductColor = lazy(() => import("./ProductColor"));
const Sync = lazy(() => import("./Sync"));
const Faqs = lazy(() => import("./Faqs"));
const Settings = lazy(() => import("./Settings"));
const FilterOptionGroups = lazy(() => import("./FilterOptionGroups"));
const Customization = lazy(() => import("./Customization"));
const Analytics = lazy(() => import("./Analytics"));
const FilterGroups = lazy(() => import("./FilterGroups"));
const Search = lazy(() => import("./Search"));

const App = ({ match }) => {
  const location = useLocation();

  const isCustomizationPage =
    location.pathname.includes(PATH_CUSTOMIZATION + "/editCode/themes/") ||
    location.pathname.includes(PATH_CUSTOMIZATION + "/customize/themes/");

  // Plan exceeded warning banner
  const { planExceeded } = useSubPlan();
  const { goToPricingPage } = usePricing();
  const planExceededBannerMarkup = planExceeded ? (
    <Banner
      className={"gx-mb-3"}
      title={<span>Subscription plan exceeded.</span>}
      description={
        <div>
          <p>
            You exceed your plan quota (Total products in your store greater
            than the limitation of total products of your plan).
          </p>
          <p>
            Please upgrade to a higher plan to keep all features still work in
            your store.
          </p>
        </div>
      }
      action={{
        content: "Upgrade",
        onAction: goToPricingPage
      }}
      status="error"
    />
  ) : null;

  return (
    <div
      className={
        "gx-main-content-wrapper" +
        (isCustomizationPage ? " gx-main-content-wrapper-customization" : "")
      }
    >
      {planExceededBannerMarkup}
      <Suspense fallback={<LazyLoad />}>
        <Switch>
          <Route path={`${match.url}home`} component={Home} />
          <Route path={`${match.url}sync`} component={Sync} />
          <Route path={`${match.url}analytics`} component={Analytics} />
          <Route path={`${match.url}filterGroups`} component={FilterGroups} />
          <Route path={`${match.url}filterOptionGroups`} component={FilterOptionGroups} />
          <Route path={`${match.url}search`} component={Search} />
          <Route path={`${match.url}themeSetup`} component={ThemeSetup} />
          <Route path={`${match.url}customization`} component={Customization} />
          <Route path={`${match.url}productColor`} component={ProductColor} />
          <Route path={`${match.url}integration`} component={Integration} />
          <Route path={`${match.url}settings`} component={Settings} />
          <Route path={`${match.url}faqs`} component={Faqs} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
