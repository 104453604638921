import { useReqQuery } from "../../hooks/query";
import themeAPI from "../../services/theme";

const useCheckOs2 = themeId => {
  const query = useReqQuery(["check-os2", themeId], async () => {
    const { data } = await themeAPI.checkOs2(themeId);
    return data;
  }, {
    enabled: themeId !== undefined,
    refetchOnWindowFocus: true,
  });

  return {
    data: query.data ? query.data : {},
    isLoading: query.isFetching,
    isError: query.isError
  };
};

export default useCheckOs2;
