import useSubPlan from "./useSubPlan";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useLiveChat = () => {
  const { planName, planPrice } = useSubPlan();
  const email = useSelector(({ auth }) => auth.authUser?.email);
  const shopifyDomain = useSelector(({ auth }) => auth.authUser?.store?.url);
  const loginToken = useSelector(({ auth }) => auth.authUser?.loginToken);

  const [isChatOpened, setIsChatOpened] = useState(false);

  useEffect(() => {
    if (window && window.$crisp) {
      window.$crisp.push([
        "on",
        "chat:opened",
        function() {
          setIsChatOpened(true);
        }
      ]);
    }
    return () => {
      if (window && window.$crisp) {
        window.$crisp.push(["off", "chat:opened"]);
      }
    };
  }, [window?.$crisp]);


  const pushData = (key, value) => {
    if (value !== undefined) {
      window.$crisp.push(["set", "session:data", [key, value]]);
    }
  }

  useEffect(() => {
    if (
      isChatOpened === true &&
      (planName !== undefined || planPrice !== undefined)
    ) {
      let loginUrl = window.origin + "/signin?loginToken=" + loginToken;

      pushData("email", email);
      pushData("login", loginUrl);
      pushData("plan_name", planName);
      pushData("plan_price", planPrice);
      pushData("shopify_domain", shopifyDomain);
    }
  }, [isChatOpened, email, planName, planPrice, shopifyDomain, loginToken]);

  const handleLiveChat = e => {
    e.preventDefault();
    if (window && window.$crisp) {
      window.$crisp.push(["do", "chat:open"]);
    }
  };

  return { handleLiveChat };
};

export default useLiveChat;
