import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const create = (pin) => {
  const url = `${ENDPOINT}/auth/pins`;
  return axios({
    url,
    data: pin,
    method: "post",
    withCredentials: true,
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/pins?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateAPin = (pin) => {
  const url = `${ENDPOINT}/auth/pins/${pin.id}`;
  const {
    searchTerm, pinnedProducts,
    applyToInstantSearch, applyToSearchResults, enable
  } = pin;
  return axios({
    url,
    method: "put",
    data: {
      searchTerm,
      pinnedProducts,
      applyToInstantSearch,
      applyToSearchResults,
      enable,
    },
    withCredentials: true,
  })
};

const deleteAPin = (pinId) => {
  const url = `${ENDPOINT}/auth/pins/${pinId}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
  })
};

const deleteManyPins = (pinIds) => {
  const url = `${ENDPOINT}/auth/pins/bulk_delete`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: pinIds,
    },
    withCredentials: true,
  })
};

const pinAPI = {
  create,
  getAll,
  updateAPin,
  deleteAPin,
  deleteManyPins,
};

export default pinAPI;

