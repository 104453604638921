import {createSlice} from "@reduxjs/toolkit";
import {updateItemEnableStatus} from "./utils";

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
};

const INIT_UPDATE_A_ITEM = {
  showEditAItemModal: false,
  currentEditItem: {},
};

const INIT_SYNC_BANNER = {
  displayDfSfSyncBanner: false,
}

const INIT_STATE = {
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_SYNC_BANNER,
};

const slice = createSlice({
  name: "defaultSearchField",
  initialState: INIT_STATE,
  reducers: {
    // Get all default search fields
    getAllDefaultSearchFields() {
    },

    getAllDefaultSearchFieldsSuccess(state, action) {
      const {total, results} = action.payload;
      return {
        ...state,
        totalItems: total,
        items: results,
      }
    },

    // Update a default search field
    updateADefaultSearchField: {
      reducer(state) {
        return state;
      },
      prepare(searchField) {
        return {payload: {searchField}};
      }
    },

    updateADefaultSearchFieldSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    showEditADefaultSearchFieldModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditADefaultSearchFieldModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Update status a item
    updateStatusADefaultSearchField() {
    },

    updateStatusADefaultSearchFieldSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showDfSfSyncBanner(state) {
      state.displayDfSfSyncBanner = true;
    },

    hideDfSfSyncBanner(state) {
      state.displayDfSfSyncBanner = false;
    }
  }
});

export const {
  getAllDefaultSearchFields,
  getAllDefaultSearchFieldsSuccess,
  updateADefaultSearchField,
  updateADefaultSearchFieldSuccess,
  showEditADefaultSearchFieldModal,
  hideEditADefaultSearchFieldModal,
  updateStatusADefaultSearchField,
  updateStatusADefaultSearchFieldSuccess,
  showDfSfSyncBanner,
  hideDfSfSyncBanner,
} = slice.actions;

export default slice.reducer;
