import {useEffect} from "react";
import {message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {hideMessage} from "../../appRedux/slices/Common"

const useNotification = ({controlled = true, msgContent, errorContent, successDuration = 5, errorDuration = 30} = {}) => {
  const dispatch = useDispatch();
  let msg = useSelector(({common}) => common.message);
  let error = useSelector(({common}) => common.error);

  if (!controlled) {
    msg = msgContent;
    error = errorContent;
  }

  useEffect(() => {
    if (msg) {
      message.success( {
        content: msg,
        key: msg,
        duration: successDuration,
      });
      dispatch(hideMessage());
    }
    if (error) {
      message.error({
        content: error,
        key: error,
        duration: errorDuration,
      });
      dispatch(hideMessage());
    }
  }, [dispatch, error, msg]);
};

export default useNotification;
