import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import pinAPI from "../../services/pin"
import {
  fetchItemIdStart,
  fetchItemIdSuccess,
  fetchItemIdError,
  fetchError, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess,
  fetchStart,
  fetchSuccess,
  showErrorMessage,
  showMessage,
} from "../slices/Common";
import {
  createAPin,
  createAPinSuccess,
  getAllPins,
  getAllPinsSuccess,
  searchProducts,
  searchProductsSuccess,
  searchProductsLoadMore,
  searchProductsLoadMoreSuccess,
  updateAPin,
  updateAPinSuccess,
  deleteAPin,
  deleteManyPins,
  deleteManyPinsSuccess, updateStatusAPinSuccess, updateStatusAPin,
} from "../slices/Pin";
import searchProductsAPI from "../../services/searchProducts";
import {LOAD_MORE_PRODUCTS_LOADING_ID} from "../../constants/loadingIds/SearchProducts";

// Create a pin
function* createAPinRequest({payload}) {
  const pin = payload;

  try {
    if (pin.pinnedProducts.length <= 0) {
      yield put(showErrorMessage("Please pin a at least a product!"));
      return;
    }

    yield put(fetchStart());
    yield call(pinAPI.create, pin);
    yield put(createAPinSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Pin created"));
    yield put(getAllPins());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAPinSaga() {
  yield takeEvery(createAPin, createAPinRequest);
}

// Search products
function* searchProductsRequest({payload}) {
  const {bodyData, metadata} = payload;

  try {
    yield put(fetchStart());
    const {data} = yield call(searchProductsAPI.searchProducts, bodyData);
    yield put(searchProductsSuccess(data, metadata));
    yield put(fetchSuccess());
  } catch (error) {
    yield put(fetchError());
  }
}

function* searchProductsSaga() {
  yield takeEvery(searchProducts, searchProductsRequest)
}

// Search products
function* searchProductsLoadMoreRequest({payload}) {
  const {bodyData, metadata} = payload;
  try {
    yield put(fetchItemIdStart(LOAD_MORE_PRODUCTS_LOADING_ID));
    const {data} = yield call(searchProductsAPI.searchProducts, bodyData);
    yield put(searchProductsLoadMoreSuccess(data, metadata));
    yield put(fetchItemIdSuccess(LOAD_MORE_PRODUCTS_LOADING_ID));
  } catch (error) {
    yield put(fetchItemIdError(LOAD_MORE_PRODUCTS_LOADING_ID));
  }
}

function* searchProductsLoadMoreSaga() {
  yield takeEvery(searchProductsLoadMore, searchProductsLoadMoreRequest)
}

// Get all pins
function* getAllPinsRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(pinAPI.getAll, payload);
    yield put(getAllPinsSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllPinsSaga() {
  yield takeEvery(getAllPins, getAllPinsRequest)
}

// Update a pin
function* updateAPinRequest({payload}) {
  const {pin, metadata} = payload;
  const {offset, pinSearchTerm} = metadata;

  try {
    yield put(fetchPartialStart());
    yield call(pinAPI.updateAPin, pin);
    yield put(updateAPinSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Pin updated"));
    yield put(getAllPins({offset, search: pinSearchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateAPinSaga() {
  yield takeEvery(updateAPin, updateAPinRequest);
}

// Update status of a pin
function* updateStatusAPinRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(pinAPI.updateAPin, item);
    yield put(updateStatusAPinSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Pin ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id));
  }

}

function* updateStatusAPinSaga() {
  yield takeEvery(updateStatusAPin, updateStatusAPinRequest);
}

// Delete a pin
function* deleteAPinRequest({payload}) {
  const pin = payload;
  try {
    yield put(fetchPartialStart());
    yield call(pinAPI.deleteAPin, pin.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Pin deleted"));
    yield put(getAllPins());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAPinSaga() {
  yield takeEvery(deleteAPin, deleteAPinRequest);
}

// Delete many pins
function* deleteManyPinsRequest({payload}) {
  const ids = payload.map(p => p.id);

  try {
    yield put(fetchPartialStart());
    yield call(pinAPI.deleteManyPins, ids);
    yield put(deleteManyPinsSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage(`Selected pins deleted`));
    yield put(getAllPins());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManyPinsSaga() {
  yield takeEvery(deleteManyPins, deleteManyPinsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createAPinSaga),
    fork(getAllPinsSaga),
    fork(searchProductsSaga),
    fork(searchProductsLoadMoreSaga),
    fork(deleteAPinSaga),
    fork(updateAPinSaga),
    fork(updateStatusAPinSaga),
    fork(deleteManyPinsSaga),
  ]);
}
