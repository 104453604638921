import {createSlice} from "@reduxjs/toolkit";

const INIT_STATE = {};

const slice = createSlice({
  name: "settings",
  initialState: INIT_STATE,
  reducers: {
    getAllSettings() {},

    getAllSettingsSuccess(state, action) {
      return {...state, ...action.payload}
    }
  }
});

export const {
  getAllSettings,
  getAllSettingsSuccess,
} = slice.actions;

export default slice.reducer;
