import { useReqMutation, useReqQuery } from "../../../hooks/query";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import syncAPI from "../../../services/sync";
import { useQueryClient } from "react-query";
import { isCompleted, isFailed, isCanceled, isRunning } from "./utils";
import {
  SYNC_TYPE_SYNC_ALL,
  SYNC_TYPE_SYNC_PROD_POS_M_COLL,
  SYNC_TYPE_SYNC_PRODUCT_REVIEWS
} from "./constants";
import {
  cancelSyncSuccess,
  syncEnded,
  syncFailed,
  syncStarted,
  syncSuccess
} from "../../../appRedux/slices/Sync";
import { Button, message, notification, Spin } from "antd";
import { useHistory } from "react-router-dom";
import TextSemiBold from "../../../components/TextSemiBold";
import useLiveChat from "../../../hooks/useLiveChat";

// const syncTypeToStatusFuncMap = {
//   [SYNC_TYPE_SYNC_ALL]: syncAPI.getSyncAllStatus,
//   [SYNC_TYPE_SYNC_PROD_POS_M_COLL]: syncAPI.getSyncProdPosMCollStatus,
//   "": syncAPI.getAllSyncStatus,
//   undefined:syncAPI.getAllSyncStatus,
// }

export const useIntervalGetSyncAllStatus = ({
  enabled = true,
  startInterval = true,
  queryKey = "get-sync-all-status"
} = {}) => {
  const { data, isError, isLoading, isFetching } = useReqQuery(
    queryKey,
    async () => {
      const { data } = await syncAPI.getAllSyncStatus();
      return data;
    },
    { enabled: enabled, refetchInterval: startInterval ? 2000 : false }
  );

  // Check finish check or not
  const [checkStatusFinished, setCheckStatusFinished] = useState(false);
  const isFinished = useCallback(
    status =>
      !isLoading &&
      !isFetching &&
      (isCompleted(status) || isFailed(status) || isCanceled(status)),
    [isFetching, isLoading]
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    // // Get sync status with sync type
    // if (syncType) {
    //   const status = data?.syncAll?.status;
    //   setCheckStatusFinished(isFinished(status));
    // } else {
    //   // For the first time load page, call get sync status.
    //   // No finish checking if one of all sync type still RUNNING,
    //   for (const k in data) {
    //     const statusObj = data[k];
    //     if (statusObj) {
    //       const status = statusObj.status;
    //       if (!isFinished(status)) {
    //       console.log("NOT FINISH CHECK STATUS WITH SYNC TYPE EMPTY ....");
    //         setCheckStatusFinished(false);
    //         return;
    //       }
    //     }
    //   }
    //   console.log("FINISHED CHECK STATUS WITH SYNC TYPE EMPTY:", data);
    //   setCheckStatusFinished(true);
    // }

    // For the first time load page, call get sync status.
    // No finish checking if one of all sync type still RUNNING,
    for (const k in data) {
      const statusObj = data[k];
      if (statusObj) {
        const status = statusObj.status;
        if (!isFinished(status)) {
          setCheckStatusFinished(false);
          return;
        }
      }
    }
    setCheckStatusFinished(true);
  }, [data, isFinished]);

  const [syncStatus, setSyncStatus] = useState({});
  const [lastSyncAt, setLastSyncAt] = useState({});
  useEffect(() => {
    if (!data) {
      return;
    }

    const syncStatusTmp = {};
    const lastSyncAtTmp = {};
    for (const k in data) {
      const statusObj = data[k];
      if (statusObj) {
        syncStatusTmp[k] = statusObj.status;
        lastSyncAtTmp[k] = statusObj.endTime;
      }
    }
    setSyncStatus(syncStatusTmp);
    setLastSyncAt(lastSyncAtTmp);
  }, [data]);

  return {
    status: syncStatus,
    lastSyncAt,
    isError,
    isLoading,
    isFetching,
    checkStatusFinished
  };
};

// Sync all
const syncTypeToSyncFuncMap = {
  [SYNC_TYPE_SYNC_ALL]: syncAPI.syncAll,
  [SYNC_TYPE_SYNC_PROD_POS_M_COLL]: syncAPI.syncProdPosMColl,
  [SYNC_TYPE_SYNC_PRODUCT_REVIEWS]: syncAPI.syncProductReviews,
  "": syncAPI.syncAll,
  undefined: syncAPI.syncAll
};

export const useSync = (syncType=SYNC_TYPE_SYNC_ALL) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const initialState = {
    startCheckStatus: true,
    startSync: false,
    firstCheckStatus: true
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "END_FIRST_CHECK_STATUS":
        return { ...state, startCheckStatus: false, firstCheckStatus: false };
      case "START_SYNC":
        dispatch(syncStarted(syncType ? syncType : SYNC_TYPE_SYNC_ALL));
        return { ...state, startSync: true, startCheckStatus: true };
      case "END_SYNC":
        dispatch(syncEnded());
        return { ...state, startSync: false, startCheckStatus: false };
      default:
        throw new Error();
    }
  };
  const [state, dispatchR] = useReducer(reducer, initialState);

  // Sync all
  // const [startSyncAll, setStartSyncAll] = useState(false);
  const mutation = useReqMutation(() => syncTypeToSyncFuncMap[syncType](), {
    onSuccess: async () => {
      // After request syncAll, let start interval check status of syncAll process
      await queryClient.invalidateQueries("get-sync-all-status");
      dispatchR({ type: "START_SYNC" });
    }
  });

  const sync = () => mutation.mutate();

  // Interval get status
  const getStatusH = useIntervalGetSyncAllStatus({
    syncType: state.startSync ? syncType : "",
    startInterval: state.startCheckStatus
  });

  // Check status on first time load page, if completed, or failed, stop checking
  useEffect(() => {
    if (state.firstCheckStatus && getStatusH.checkStatusFinished) {
      dispatchR({ type: "END_FIRST_CHECK_STATUS" });
    }
  }, [state.firstCheckStatus, getStatusH.checkStatusFinished]);

  // After sync, if completed, failed, canceled
  useEffect(() => {
    if (state.startSync && getStatusH.checkStatusFinished) {
      // if (isCompleted(getStatusH.status[syncType])) {
      // dispatch(showMessage("Store data synced!"));

      // }
      dispatchR({ type: "END_SYNC" });
    }
  }, [getStatusH.checkStatusFinished, state.startSync]);

  // Check current sync type is disabled or not
  // Disabled when another sync type is running
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (!getStatusH?.status) {
      return;
    }

    let runningSynctype = "";
    for (const k in getStatusH.status) {
      if (isRunning(getStatusH.status[k])) {
        runningSynctype = k;
        break;
      }
    }

    // If another sync type is running, disable current sync type
    if (runningSynctype !== "" && runningSynctype !== syncType) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [getStatusH.status, syncType]);

  return {
    sync,
    isLoading:
      !isDisabled &&
      (mutation.isLoading ||
        getStatusH.isFetching ||
        isRunning(getStatusH.status[syncType])),
    isError: mutation.isError || getStatusH.isError,
    status: getStatusH.status,
    lastSyncAt: getStatusH.lastSyncAt,
    isSyncFailed: isFailed(getStatusH.status[syncType]),
    isDisabled
  };
};

export const syncTypeToTextMap = {
  [SYNC_TYPE_SYNC_ALL]: "Sync all",
  [SYNC_TYPE_SYNC_PROD_POS_M_COLL]: "Sync collections sorted by Manually",
  [SYNC_TYPE_SYNC_PRODUCT_REVIEWS]: "Sync product reviews",
  "": "Sync all",
  undefined: "Sync all",
};

export const useGetSyncStatusOfAllTye = () => {
  const dispatch = useDispatch();

  const initialState = {
    startCheckStatus: true,
    firstCheckStatus: true
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "END_FIRST_CHECK_STATUS":
        return { ...state, startCheckStatus: false, firstCheckStatus: false };
      case "START_CHECK_STATUS":
        return { ...state, startCheckStatus: true };
      case "END_CHECK_STATUS":
        dispatch(syncEnded());
        return { ...state, startCheckStatus: false };
      default:
        throw new Error();
    }
  };
  const [state, dispatchR] = useReducer(reducer, initialState);

  // Interval get status
  const getStatusH = useIntervalGetSyncAllStatus({
    startInterval: state.startCheckStatus
  });

  // If sync started, auto request get sync status
  const isSyncStarted = useSelector(({ sync }) => sync.syncStarted);
  useEffect(() => {
    if (isSyncStarted) {
      dispatchR({ type: "START_CHECK_STATUS" });
    } else {
      dispatchR({ type: "END_CHECK_STATUS" });
    }
  }, [isSyncStarted]);

  // Check status on first time load page, if completed, or failed, stop checking
  useEffect(() => {
    if (state.firstCheckStatus && getStatusH.checkStatusFinished) {
      dispatchR({ type: "END_FIRST_CHECK_STATUS" });
    }
  }, [state.firstCheckStatus, getStatusH.checkStatusFinished]);

  // After sync, if completed, failed, canceled
  useEffect(() => {
    if (isSyncStarted && getStatusH.checkStatusFinished) {
      dispatchR({ type: "END_CHECK_STATUS" });
    }
  }, [getStatusH.checkStatusFinished, isSyncStarted]);

  const syncType = useSelector(({ sync }) => sync.syncType);
  // Check completed
  useEffect(() => {
    if (!getStatusH) {
      return;
    }

    if (!state.firstCheckStatus && isCompleted(getStatusH.status[syncType])) {
      dispatch(syncSuccess());
    }
  }, [dispatch, getStatusH, state.firstCheckStatus, syncType]);

  // Check cancel
  useEffect(() => {
    if (!getStatusH) {
      return;
    }
    if (!state.firstCheckStatus && isCanceled(getStatusH.status[syncType])) {
      dispatch(cancelSyncSuccess());
    }
  }, [dispatch, getStatusH, state.firstCheckStatus, syncType]);

    // Check failed
    useEffect(() => {
      if (!getStatusH) {
        return;
      }
      if (!state.firstCheckStatus && isFailed(getStatusH.status[syncType])) {
        dispatch(syncFailed());
      }
    }, [dispatch, getStatusH, state.firstCheckStatus, syncType]);

  // Check running
  const [isAllRunning, setIsAllRunning] = useState(false);
  useEffect(() => {
    if (!getStatusH) {
      return;
    }

    let isRunningTemp = false;
    for (const [, status] of Object.entries(getStatusH.status)) {
      if (isRunning(status)) {
        isRunningTemp = true;
      }
    }
    setIsAllRunning(isRunningTemp);
  }, [getStatusH, getStatusH.status]);

  // Show notify
  const isSyncSuccess = useSelector(({ sync }) => sync.syncSuccess);
  const isCancelSyncSuccess = useSelector(({ sync }) => sync.cancelSyncSuccess);
  const isSyncFailed = useSelector(({ sync }) => sync.syncFailed);
  const historyH = useHistory();
  const handleActionSyncSuccess = () => {
    historyH.push("/themeSetup");
    notification.close("store-data-synced");
  };

  // Check first time sync or not
  const firstIndexing = useSelector(({auth}) => auth?.authUser?.store?.firstIndexing);
  const [isFirstTimeSync, setIsFirstTimeSync] = useState(false);
  useEffect(() => {
    if (isSyncStarted && firstIndexing !== undefined) {
      setIsFirstTimeSync(!firstIndexing);
    }
  }, [firstIndexing, isSyncStarted])

  // start sync
  useEffect(() => {
    if (isSyncStarted) {
      const hide = message.loading({content: 'Store data syncing ...', key: "sync-started"});
      // Dismiss manually and asynchronously
      setTimeout(hide, 2500);
    }
  }, [isSyncStarted]);

  // After sync success
  useEffect(() => {
    if (isSyncSuccess && !isFirstTimeSync) {
      notification["success"]({
        key: "store-data-synced",
        message: "Store data synced.",
        description: (
          <div>
            <p>
              Go to installed themes to preview your storefront.
            </p>
            <Button
              className="gx-mb-0"
              onClick={handleActionSyncSuccess}
            >
              Go to installed themes
            </Button>
          </div>
        ),
        duration: 0
      });
    }
  }, [isSyncSuccess]);

  useEffect(() => {
    if (isCancelSyncSuccess) {
      notification["success"]({
        key: "cancel-sync-success",
        message: "Sync data canceled.",
        description: (
          <div>
            <TextSemiBold>{syncTypeToTextMap[syncType]}</TextSemiBold> canceled.
          </div>
        ),
        duration: 0
      });
    }
  }, [isCancelSyncSuccess, syncType]);

  const { handleLiveChat } = useLiveChat();
  const handleActionSyncFailed = (e) => {
    notification.close("sync-data-failed");
    handleLiveChat(e);
  }
  useEffect(() => {
    if (isSyncFailed) {
      notification["error"]({
        key: "sync-data-failed",
        message: "Sync data failed.",
        description: (
          <div>
            <p>
              The failed due to our internal error. You could try again. If you still got failed, please contact us.
            </p>
            <Button
              className="gx-mb-0"
              onClick={handleActionSyncFailed}
            >
              Live chat now
            </Button>
          </div>
        ),
        duration: 0
      });
    }
  }, [isSyncFailed]);

  return {
    // isLoading: getStatusH.isFetching || isAllRunning,
    isLoading: isAllRunning,
    isError: getStatusH.isError,
    lastSyncAt: getStatusH.lastSyncAt
  };
};

// Cancel sync all
const syncTypeToCancelFuncMap = {
  [SYNC_TYPE_SYNC_ALL]: syncAPI.cancelSyncAll,
  [SYNC_TYPE_SYNC_PROD_POS_M_COLL]: syncAPI.cancelSyncProPosMColl,
  [SYNC_TYPE_SYNC_PRODUCT_REVIEWS]: syncAPI.cancelSyncProductReviews,
  "": syncAPI.cancelSyncAll,
  undefined: syncAPI.cancelSyncAll
};
export const useCancelSync = syncType => {
  const queryClient = useQueryClient();

  const initialState = {
    enableCheckStatus: true,
    firstCheckStatus: true,
    startCheckStatus: true,
    startCancel: false
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "END_FIRST_CHECK_STATUS":
        return {
          ...state,
          enableCheckStatus: false,
          firstCheckStatus: false,
          startCheckStatus: false
        };
      case "ENABLE_CHECK_STATUS":
        return { ...state, enableCheckStatus: true };
      case "START_CANCEL":
        return { ...state, startCancel: true, startCheckStatus: true };
      case "END_CANCEL":
        return { ...state, startCancel: false, startCheckStatus: false };
      default:
        throw new Error();
    }
  };
  const [state, dispatchR] = useReducer(reducer, initialState);

  // const queryKey = "get-sync-all-status-for-cancel";
  const queryKey = "get-sync-all-status";

  // Cancel sync
  const mutation = useReqMutation(() => syncTypeToCancelFuncMap[syncType](), {
    onSuccess: async () => {
      // After request cancel, let start interval check status of syncAll process
      await queryClient.invalidateQueries(queryKey);
      dispatchR({ type: "START_CANCEL" });
    }
  });

  const cancelSync = () => {
    dispatchR({ type: "ENABLE_CHECK_STATUS" });
    mutation.mutate();
  };

  // Interval get status
  const getStatusH = useIntervalGetSyncAllStatus({
    syncType,
    enabled: state.enableCheckStatus,
    startInterval: state.startCheckStatus,
    queryKey
  });

  // Check status on first time load page, if completed, or failed, stop checking
  useEffect(() => {
    if (state.firstCheckStatus && getStatusH.checkStatusFinished) {
      dispatchR({ type: "END_FIRST_CHECK_STATUS" });
    }
  }, [state.firstCheckStatus, getStatusH.checkStatusFinished]);

  // After finish cancel, If status completed, or failed, stop checking
  useEffect(() => {
    if (state.startCancel && getStatusH.checkStatusFinished) {
      // if (isCanceled(getStatusH.status[syncType])) {
      // dispatch(showMessage("Sync all canceled!"));
      // }
      dispatchR({ type: "END_CANCEL" });
    }
  }, [getStatusH.checkStatusFinished, state.startCancel]);

  return {
    cancelSync,
    isLoading:
      state.startCancel &&
      (mutation.isLoading ||
        getStatusH.isFetching ||
        isRunning(getStatusH.status[syncType])),
    isError: mutation.isError || getStatusH.isError,
    status: getStatusH.status,
    lastSyncAt: getStatusH.lastSyncAt,
    isSyncFailed: isFailed(getStatusH.status[syncType])
  };
};
