import {createSlice} from "@reduxjs/toolkit";
import {LAYOUT_TYPE_FULL, NAV_STYLE_FIXED, THEME_COLOR, THEME_TYPE_LITE} from "../../constants/ThemeSetting";

const INIT_STATE = {
  navCollapsed: true,
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  themeColor: THEME_COLOR,

  pathname: '/',
  width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
};

const slice = createSlice({
  name: "themeSettings",
  initialState: INIT_STATE,
  extraReducers: {
    '@@router/LOCATION_CHANGE': (state, action) => {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      };
    }
  },
  reducers: {
    toggleCollapsedSideNav(state, action) {
      return {
        ...state,
        // navCollapsed: action.navCollapsed
        navCollapsed: action.payload,
      };
    },

    updateWindowWidth(state, action) {
      return {
        ...state,
        // width: action.width,
        width: action.payload,
      };
    },

    setThemeType(state, action) {
      return {
        ...state,
        // themeType: action.themeType
        themeType: action.payload,
      };
    },

    setThemeColor(state, action) {
      return {
        ...state,
        // themeColor: action.themeColor
        themeColor: action.payload,
      };
    },

    onNavStyleChange(state, action) {
      return {
        ...state,
        // navStyle: action.navStyle
        navStyle: action.payload,
      };
    },

    onLayoutTypeChange(state, action) {
      return {
        ...state,
        // layoutType: action.layoutType
        layoutType: action.payload,
      };
    },

    switchLanguage(state, action) {
      return {
        ...state,
        locale: action.payload,
      };
    },
  }
});

export const {
  toggleCollapsedSideNav,
  updateWindowWidth,
  setThemeType,
  setThemeColor,
  onNavStyleChange,
  onLayoutTypeChange,
  switchLanguage,
} = slice.actions;

export default slice.reducer;
