import {DEFAULT_PAGE_SIZE} from "../../../constants/Common";

export const updateSelectedItem = (items, selectedItem) => {
  let selectedItemCount = 0;
  items.map(item => {
    if (item.id === selectedItem.id) {
      item.selected = !selectedItem.selected;
      if (item.selected) {
        selectedItemCount++;
      }
    }
    if (item.selected) {
      selectedItemCount++;
    }
    return item;
  });

  return selectedItemCount;
};

export const updateSelectedItems = (items, state) => {
  return items.map(item => {
    item.selected = state;
    return item;
  });
};

export const updateItemEnableStatus = (enabledItem, items) => {
  return items.map(item => {
    if (item.id === enabledItem.id) {
      item.enable = enabledItem.enable;
    }
    return item;
  });
};

export const processParamsForGetAllItems = (params) => {
  if (params === undefined) {
    params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
  } else {
    params.offset = params.offset || 1;
    params.limit = params.limit || DEFAULT_PAGE_SIZE;
    params.search = params.search || '';
  }

  return params;
};
