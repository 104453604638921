import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const createOne = (item) => {
  const url = `${ENDPOINT}/auth/merchandisings`;
  return axios({
    url,
    data: item,
    method: "post",
    withCredentials: true,
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/merchandisings?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateOne = (item) => {
  const url = `${ENDPOINT}/auth/merchandisings/${item.id}`;
  const {
    name, searchTerms, conditions,
    applyToInstantSearch, applyToSearchResults, enable
  } = item;
  return axios({
    url,
    method: "put",
    data: {
      name,
      searchTerms,
      conditions,
      applyToInstantSearch,
      applyToSearchResults,
      enable,
    },
    withCredentials: true,
  })
};

const deleteOne = (itemId) => {
  const url = `${ENDPOINT}/auth/merchandisings/${itemId}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
  })
};

const deleteMany = (itemIds) => {
  const url = `${ENDPOINT}/auth/merchandisings/bulk_delete`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: itemIds,
    },
    withCredentials: true,
  })
};

const merchandisingAPI = {
  createOne,
  getAll,
  updateOne,
  deleteOne,
  deleteMany,
};

export default merchandisingAPI;
