import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchItemIdError, fetchItemIdStart, fetchItemIdSuccess, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createAFilter,
  createAFilterSuccess,
  getAllFilters,
  getAllFiltersSuccess,
  deleteAFilter,
  deleteManyFilters,
  deleteManyFiltersSuccess,
  updateAFilter,
  updateAFilterSuccess, updateStatusAFilterSuccess, updateStatusAFilter, updatePositions,
} from "../slices/Filter";
import filterAPI from "../../services/filter";

// Create a filter
function* createAFilterRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(filterAPI.createOne, item);
    yield put(createAFilterSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Filter created"));
    yield put(getAllFilters({filterGroupId: item.filterGroupId}));
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAFilterSaga() {
  yield takeEvery(createAFilter, createAFilterRequest);
}

// Get all filters
function* getAllFiltersRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(filterAPI.getAll, payload);
    yield put(getAllFiltersSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllFiltersSaga() {
  yield takeEvery(getAllFilters, getAllFiltersRequest)
}

// Update a filter
function* updateAFilterRequest({payload}) {
  const {filter, metadata} = payload;
  const {filterGroupId, searchTerm} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(filterAPI.updateOne, filter);
    yield put(updateAFilterSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Filter updated"));
    yield put(getAllFilters({filterGroupId, search: searchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateAFilterSaga() {
  yield takeEvery(updateAFilter, updateAFilterRequest);
}

// Update status of a filter
function* updateStatusAFilterRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(filterAPI.updateOne, item);
    yield put(updateStatusAFilterSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Filter ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id));
  }
}

function* updateStatusAFilterSaga() {
  yield takeEvery(updateStatusAFilter, updateStatusAFilterRequest);
}

// Delete a filter
function* deleteAFilterRequest({payload}) {
  try {
    const filter = payload;
    yield put(fetchPartialStart());
    yield call(filterAPI.deleteOne, filter.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Filter deleted"));
    yield put(getAllFilters({filterGroupId: filter.filterGroupId}));
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAFilterSaga() {
  yield takeEvery(deleteAFilter, deleteAFilterRequest);
}

// Delete many filters
function* deleteManyFiltersRequest({payload}) {
  try {
    const ids = payload.map(p => p.id);
    yield put(fetchPartialStart());
    yield call(filterAPI.deleteMany, ids);
    yield put(deleteManyFiltersSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Selected filters deleted"));
    yield put(getAllFilters({filterGroupId: payload[0].filterGroupId}));

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManyFiltersSaga() {
  yield takeEvery(deleteManyFilters, deleteManyFiltersRequest);
}

function* updatePositionsRequest({payload}) {
  const newOrderedItems = payload;
  try {
    yield call(filterAPI.updatePositions, newOrderedItems.map(item => item.id));
  } catch (error) {
    yield put(fetchPartialError());
    yield put(getAllFilters({filterGroupId: newOrderedItems[0].filterGroupId}));
  }
}

function* updatePositionsSaga() {
  yield takeEvery(updatePositions, updatePositionsRequest)
}

export default function* rootSaga() {
  yield all([
    fork(createAFilterSaga),
    fork(getAllFiltersSaga),
    fork(deleteAFilterSaga),
    fork(updateAFilterSaga),
    fork(updateStatusAFilterSaga),
    fork(deleteManyFiltersSaga),
    fork(updatePositionsSaga)
  ]);
}
