import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const createOne = (item) => {
  const url = `${ENDPOINT}/auth/metafieldSearchFields`;
  return axios({
    url,
    data: item,
    method: "post",
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/metafieldSearchFields?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateOne = (item) => {
  const url = `${ENDPOINT}/auth/metafieldSearchFields/${item.id}`;
  const {
    namespace, key, isValueTypeMetaobject, metaobjectFieldKey, valueDataType, delimiter, weight, searchForProducts, buildSearchSuggestion, enable
  } = item;
  return axios({
    url,
    method: "put",
    data: {
      namespace,
      key,
      isValueTypeMetaobject,
      metaobjectFieldKey,
      valueDataType,
      delimiter,
      weight,
      searchForProducts,
      buildSearchSuggestion,
      enable,
    },
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

const deleteOne = (itemId) => {
  const url = `${ENDPOINT}/auth/metafieldSearchFields/${itemId}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

const metafieldSearchFieldAPI = {
  createOne,
  getAll,
  updateOne,
  deleteOne,
};

export default metafieldSearchFieldAPI;
