const docs = {
  gettingsStarted: {
    installLayout: {
      installToOs2Theme: "https://docs.flashsearch.io/getting-started/install-layout/install-to-a-theme-os-2.0-supported",
    }
  },
  analytics: "https://docs.flashsearch.io/main-function/analytics",
  filters: "https://docs.flashsearch.io/filters/filter-groups",
  pinning: "https://docs.flashsearch.io/search/pinning",
  merchandising:
    "https://docs.flashsearch.io/main-function/search/merchandising",
  synonyms: "https://docs.flashsearch.io/search/synonyms",
  redirect: "https://docs.flashsearch.io/search/redirect",
  themeSetup: "https://docs.flashsearch.io/getting-started/install-layout",
  customization: {
    main: "https://docs.flashsearch.io/layout/customization",
    themeCustomization:
      "https://docs.flashsearch.io/layout/customization/customize",
    layoutTemplate:
      "https://docs.flashsearch.io/layout/customization/import"
  },
  integration: {
    main: "https://docs.flashsearch.io/integrations/product-reviews",
    ryviu: "https://docs.flashsearch.io/integrations/product-reviews/ryviu-aliexpress-reviews-app",
    growave: "https://docs.flashsearch.io/integrations/product-reviews/growave-loyalty-reviews-wishlist-+2",
    loox: "https://docs.flashsearch.io/integrations/product-reviews/loox-product-reviews-and-photos",
    stampedio: "https://docs.flashsearch.io/integrations/product-reviews/stamped-product-reviews-and-ugc",
    judgeme: "https://docs.flashsearch.io/integrations/product-reviews/judge.me-product-reviews",
    yotpo:
      "https://docs.flashsearch.io/integrations/product-reviews/yotpo-product-reviews-and-photos",
    weglot:
      "https://docs.flashsearch.io/integrations/languages/translate-your-store-weglot",
      wishlist: {
        growave: "https://docs.flashsearch.io/integrations/wishlist/growave-loyalty-reviews-wishlist-+2",
        wishlistPlus: "https://docs.flashsearch.io/integrations/wishlist/wishlist-plus",
        wishlistHero: "https://docs.flashsearch.io/integrations/wishlist/wishlist-hero",
        wishlistKing: "https://docs.flashsearch.io/integrations/wishlist/wishlist-king",
        smartWishlist: "https://docs.flashsearch.io/integrations/wishlist/smart-wishlist",
      }
  },
  settings: {
    usageStatus:
      "https://docs.flashsearch.io/main-function/settings/usage-status",
    searchFields:
      "https://docs.flashsearch.io/settings/search-fields",
    howToGetMetaobjectFieldKey: "https://docs.flashsearch.io/settings/search-fields/how-to-get-metaobject-field-key",
    myAccount: {
      main: "https://docs.flashsearch.io/main-function/settings/my-account",
      changeEmail:
        "https://docs.flashsearch.io/main-function/settings/my-account/why-i-dont-receive-your-notification-emails"
    },
    plansAndSubscriptions: {
      main:
        "https://docs.flashsearch.io/main-function/settings/plans-and-subscriptions",
      subscriptionPlan:
        "https://docs.flashsearch.io/main-function/settings/plans-and-subscriptions/subscription-plan",
      trialPlan:
        "https://docs.flashsearch.io/main-function/settings/plans-and-subscriptions/trial-plan"
    }
  }
};

export default docs;
