import {createSlice} from "@reduxjs/toolkit";
import {updateItemEnableStatus, updateSelectedItem, updateSelectedItems} from "./utils";


const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  searchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
  firstGetAllLoading: false,
};

const INIT_COMMON = {
  selectedItemCount: 0,
  isMetafieldEmpty: false,
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {},
};

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "filter",
  initialState: INIT_STATE,
  reducers: {
    // Create a filter
    createAFilter() {},

    createAFilterSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    showCreateAFilterModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        ...INIT_COMMON,
        showCreateAItemModal: true,
      }
    },

    hideCreateAFilterModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all filter
    getAllFilters() {},

    getAllFiltersSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all filters
        const items = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          items,
          totalCurrentItems: total,
          selectedItemCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalItems = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}}
      }
    },

    setFirstGetAllFiltersLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    setFilterSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setDidFirstGetAllFilterRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    // Update a filter
    updateAFilter: {
      reducer(state) {
        return state;
      },
      prepare(filter, metadata) {
        return {payload: {filter, metadata}};
      }
    },

    updateAFilterSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    updateStatusAFilter() {},

    updateStatusAFilterSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showEditAFilterModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditAFilterModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    // Delete a item
    deleteAFilter() {},

    // Delete many
    deleteManyFilters() {},

    deleteManyFiltersSuccess(state) {
      state.selectedItemCount = 0;
    },

    // Common
    updateSelectedFilter(state, action) {
      state.selectedItemCount = updateSelectedItem(state.items, action.payload);
    },

    updateAllFiltersToSelected(state) {
      updateSelectedItems(state.items, true);
      state.selectedItemCount = state.items.length;
    },

    updateAllFiltersToUnSelected(state) {
      updateSelectedItems(state.items, false);
      state.selectedItemCount = 0;
    },

    showEmptyMetafieldMsg(state) {
      state.isMetafieldEmpty = true;
    },

    hideEmptyMetafieldMsg(state) {
      state.isMetafieldEmpty = false;
    },

    updatePositions(state, action) {
      state.items = action.payload;
    }
  }
});

export const {
  createAFilter,
  createAFilterSuccess,
  showCreateAFilterModal,
  hideCreateAFilterModal,
  getAllFilters,
  getAllFiltersSuccess,
  setFirstGetAllFiltersLoading,
  setFilterSearchTerm,
  setDidFirstGetAllFilterRequest,
  updateAFilter,
  updateAFilterSuccess,
  updateStatusAFilter,
  updateStatusAFilterSuccess,
  showEditAFilterModal,
  hideEditAFilterModal,
  deleteAFilter,
  deleteManyFilters,
  deleteManyFiltersSuccess,
  updateSelectedFilter,
  updateAllFiltersToSelected,
  updateAllFiltersToUnSelected,
  showEmptyMetafieldMsg,
  hideEmptyMetafieldMsg,
  updatePositions,
} = slice.actions;

export default slice.reducer;
