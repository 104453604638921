import { useSelector } from "react-redux";
import { makeCreateSetupThemeReqData } from "./utils";
import useCreateSetupTheme from "../hooks/theme/useCreateSetupTheme";
import { useState } from "react";
import useDeleteManySetupThemes from "../hooks/theme/useDeleteManySetupThemes";

const useHandleCreateSetupTheme = (form, { onSuccess, onSuccessCancel } = {}) => {
  const { validateFields } = form;

  const [createdSetupThemes, setCreatedSetupThemes] = useState([]);

  const { createSetupTheme, isLoading } = useCreateSetupTheme({
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess();
      }
      const createdSetupThemeId = variables?.theme?.id;
      if (createdSetupThemeId !== undefined) {
        setCreatedSetupThemes([...createdSetupThemes, createdSetupThemeId]);
      }
    }
  });

  const shopifyThemes = useSelector(
    ({ shopifyTheme }) => shopifyTheme.shopifyThemes
  );

  const handleCreateSetupTheme = e => {
    e.preventDefault();
    validateFields().then(item => {
      const reqData = makeCreateSetupThemeReqData(shopifyThemes, item);

      createSetupTheme(reqData);
    });
  };

  // Handle cancel
  const {
    deleteManySetupThemes,
    isLoading: isCancelSetupThemeLoading
  } = useDeleteManySetupThemes({
    onSuccess: () => {
      if (onSuccessCancel) {
        onSuccessCancel()
      }
    }
  });
  const handleCancelCreateSetupTheme = () => {
    if (createdSetupThemes && createdSetupThemes.length > 0) {
      deleteManySetupThemes(createdSetupThemes);
    } else {
      // call success
      if (onSuccessCancel) {
        onSuccessCancel()
      }
    }
  };

  return {
    isCreateSetupThemeLoading: isLoading,
    handleCreateSetupTheme,
    handleCancelCreateSetupTheme,
    isCancelSetupThemeLoading
  };
};

export default useHandleCreateSetupTheme;
