import React, { useEffect } from "react";
import { Button, Layout, Tooltip, Icon, Alert } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { toggleCollapsedSideNav } from "../../appRedux/slices/ThemeSettings";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { getSyncStatus } from "../../appRedux/slices/Auth";
import { tmpToDateStr } from "../../util/date";
import useNotification from "../../routes/hooks/useNotificaton";
import UpgradePlanButton from "../../components/UpgradePlanButton";
import useSubPlan from "../../hooks/useSubPlan";
import useSync from "../../hooks/useSync";
import TopbarAd from "../../components/TopbarAd";

const { Header } = Layout;

const Topbar = () => {
  const { isPremiumPlan } = useSubPlan();

  const { width, navCollapsed, navStyle } = useSelector(
    ({ themeSettings }) => themeSettings
  );

  const dispatch = useDispatch();
  const firstIndexing = useSelector(
    ({ auth }) => auth.authUser?.store?.firstIndexing
  );
  
  const syncHook = useSync();

  // Check sync done nor not
  useEffect(() => {
    let inter;
    if (firstIndexing === true) {
      if (syncHook.isRunning) {
        inter = setInterval(() => {
          try {
            dispatch(getSyncStatus(inter));
          } catch (e) {
            clearInterval(inter);
          }
        }, 3000);
      } else if (syncHook.isCompleted || syncHook.isFailed) {
        clearInterval(inter);
      }
    }

    return () => {
      clearInterval(inter);
    };
  }, [dispatch, firstIndexing, syncHook]);

  const userMarkup =
    width >= TAB_SIZE ? (
      <Auxiliary>
        <UserInfo />
      </Auxiliary>
    ) : (
      <Auxiliary>
        <li className="gx-user-nav">
          <UserInfo layoutType="mobile" />
        </li>
      </Auxiliary>
    );

  // const tooltipContent = (
  //   <span>
  //     <p>Last sync at: {tmpToDateStr(syncHook.lastSyncAt)}</p>
  //     <p>
  //       Helps the search and filter results up-to-date with your online store
  //       data (products, collections, pages, metafields...)
  //     </p>
  //   </span>
  // );

  // const syncBtnText = syncHook.isRunning ? "Syncing..." : "Sync store data";

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/fs-logo.svg")} />
      </Link>

      {/*<ul className="gx-header-notifications gx-ml-auto">*/}
      <ul className="gx-header-notifications gx-ml-auto">
        <TopbarAd/>
        {/* <Auxiliary> */}
          {/* {syncHook.isFailedOrStatusOfUserFailed ? (
            <li>
              <Alert
                message={
                  <span data-testid="sync-failed-msg">
                    Sync failed, please try again!
                  </span>
                }
                type="error"
                showIcon
                closable
                style={{ display: "inline" }}
              />
            </li>
          ) : null} */}
          {/* <li>
            <Button
              data-testid="sync-button"
              onClick={syncHook.startSync}
              loading={syncHook.isRunning}
            >
              {syncBtnText}
            </Button>
            <Tooltip title={tooltipContent}>
              <Icon type="question-circle-o" />
            </Tooltip>
          </li> */}
        {/* </Auxiliary> */}
        {!isPremiumPlan ? <UpgradePlanButton /> : null}
        {userMarkup}
      </ul>
    </Header>
  );
};

export default Topbar;
