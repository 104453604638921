import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {updateSelectedItems, updateSelectedItem, updateItemEnableStatus} from "./utils";


const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  searchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
  firstGetAllLoading: false,
};

const INIT_COMMON = {
  selectedItemCount: 0,
  error: '',
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {},
};

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "redirect",
  initialState: INIT_STATE,
  reducers: {
    // Create a redirect
    createARedirect() {},

    createARedirectSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
      }
    },

    showCreateARedirectModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        showCreateAItemModal: true,
      }
    },

    hideCreateARedirectModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all redirect
    getAllRedirects: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
          params.search = params.search || '';
        }
        return {
          payload: params
        }
      }
    },

    getAllRedirectsSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all redirects
        const items = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          items,
          totalCurrentItems: total,
          selectedItemCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalItems = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}}
      }
    },

    setFirstGetAllRedirectsLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    setRedirectSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setDidFirstGetAllRedirectRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    // Update a redirect
    updateARedirect: {
      reducer(state) {
        return state;
      },
      prepare(redirect, metadata) {
        return {payload: {redirect, metadata}};
      }
    },

    updateARedirectSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    updateStatusARedirect() {},

    updateStatusARedirectSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showEditARedirectModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditARedirectModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Delete a item
    deleteARedirect() {},

    // Delete many
    deleteManyRedirects() {},

    deleteManyRedirectsSuccess(state) {
      state.selectedItemCount = 0;
    },

    // Common
    updateSelectedRedirect(state, action) {
      state.selectedItemCount = updateSelectedItem(state.items, action.payload);
    },

    updateAllRedirectsToSelected(state) {
      updateSelectedItems(state.items, true);
      state.selectedItemCount = state.items.length;
    },

    updateAllRedirectsToUnSelected(state) {
      updateSelectedItems(state.items, false);
      state.selectedItemCount = 0;
    },
  }
});

export const {
  createARedirect,
  createARedirectSuccess,
  showCreateARedirectModal,
  hideCreateARedirectModal,
  getAllRedirects,
  getAllRedirectsSuccess,
  setFirstGetAllRedirectsLoading,
  setRedirectSearchTerm,
  setDidFirstGetAllRedirectRequest,
  updateARedirect,
  updateARedirectSuccess,
  updateStatusARedirect,
  updateStatusARedirectSuccess,
  showEditARedirectModal,
  hideEditARedirectModal,
  deleteARedirect,
  deleteManyRedirects,
  deleteManyRedirectsSuccess,
  updateSelectedRedirect,
  updateAllRedirectsToSelected,
  updateAllRedirectsToUnSelected,
} = slice.actions;

export default slice.reducer;
