import axios from "axios";

import config from "../config";
import { getPublicToken } from "../util/publicToken";

const API_ENDPOINT = config.searchApiEndpoint;

const searchProducts = (bodyData) => {
  const url = `${API_ENDPOINT}/guest/shopify/search`;

  // Always return 20 products
  bodyData.productLimit = 20;

  return axios({
    url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${getPublicToken()}`
    },
    data: bodyData,
    withCredentials: true,
  });
};

const searchProductsAPI = {
  searchProducts,
};

export default searchProductsAPI;
