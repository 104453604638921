import {createSlice} from "@reduxjs/toolkit";

const INIT_GET_ALL_SHOPIFY_THEME_STATE = {
  shopifyThemes: [],
};

const INIT_STATE = {
  ...INIT_GET_ALL_SHOPIFY_THEME_STATE,
};

const slice = createSlice({
  name: "shopifyTheme",
  initialState: INIT_STATE,
  reducers: {
    getAllShopifyThemes() {
    },

    getAllShopifyThemesSuccess(state, action) {
      state.shopifyThemes = action.payload;
    }
  }
});

export const {
  getAllShopifyThemes,
  getAllShopifyThemesSuccess,
} = slice.actions;

export default slice.reducer;
