import axios from "axios";

import config from "../config";

const API_ENDPOINT = config.apiEndpoint;

const getAllSettings = () => {
  const url = `${API_ENDPOINT}/auth/settings`;

  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const settingsAPI = {
  getAllSettings,
};

export default settingsAPI;
