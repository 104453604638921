import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchItemIdError, fetchItemIdStart, fetchItemIdSuccess, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage
} from "../slices/Common";
import {
  createAMerchandising,
  deleteAMerchandising,
  deleteManyMerchandisings,
  deleteManyMerchandisingsSuccess,
  updateAMerchandising,
  updateAMerchandisingSuccess,
  createAMerchandisingSuccess,
  getAllMerchandisings, getAllMerchandisingsSuccess, updateStatusAMerchandising, updateStatusAMerchandisingSuccess
} from "../slices/Merchandising";
import merchandisingAPI from "../../services/merchandising";

// Create a merchandising
function* createAMerchandisingRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(merchandisingAPI.createOne, item);
    yield put(createAMerchandisingSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Rule created"));
    yield put(getAllMerchandisings());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAMerchandisingSaga() {
  yield takeEvery(createAMerchandising, createAMerchandisingRequest);
}

// Get all merchandisings
function* getAllMerchandisingsRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(merchandisingAPI.getAll, payload);
    yield put(getAllMerchandisingsSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllMerchandisingsSaga() {
  yield takeEvery(getAllMerchandisings, getAllMerchandisingsRequest)
}

// Update a merchandising
function* updateAMerchandisingRequest({payload}) {
  const {merchandising, metadata} = payload;
  const {offset, searchTerm} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(merchandisingAPI.updateOne, merchandising);
    yield put(updateAMerchandisingSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Rule updated"));
    yield put(getAllMerchandisings({offset, search: searchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateAMerchandisingSaga() {
  yield takeEvery(updateAMerchandising, updateAMerchandisingRequest);
}

// Update status of a merchandising
function* updateStatusAMerchandisingRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(merchandisingAPI.updateOne, item);
    yield put(updateStatusAMerchandisingSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Rule ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id));
  }
}

function* updateStatusAMerchandisingSaga() {
  yield takeEvery(updateStatusAMerchandising, updateStatusAMerchandisingRequest);
}

// Delete a merchandising
function* deleteAMerchandisingRequest({payload}) {
  try {
    const merchandising = payload;
    yield put(fetchPartialStart());
    yield call(merchandisingAPI.deleteOne, merchandising.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Rule deleted"));
    yield put(getAllMerchandisings());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAMerchandisingSaga() {
  yield takeEvery(deleteAMerchandising, deleteAMerchandisingRequest);
}

// Delete many merchandisings
function* deleteManyMerchandisingsRequest({payload}) {
  try {
    const ids = payload.map(p => p.id);
    yield put(fetchPartialStart());
    yield call(merchandisingAPI.deleteMany, ids);
    yield put(deleteManyMerchandisingsSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Selected rules deleted"));
    yield put(getAllMerchandisings());

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManyMerchandisingsSaga() {
  yield takeEvery(deleteManyMerchandisings, deleteManyMerchandisingsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createAMerchandisingSaga),
    fork(getAllMerchandisingsSaga),
    fork(deleteAMerchandisingSaga),
    fork(updateAMerchandisingSaga),
    fork(updateStatusAMerchandisingSaga),
    fork(deleteManyMerchandisingsSaga),
  ]);
}
