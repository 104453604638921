import {Badge} from "antd";
import React from "react";
import PropTypes from "prop-types";

const CustomBadge = ({children, status, className, count, visible, ...res}) => {
  return (
    <Badge style={!visible ? {display: "none"} : undefined} count={count} className={"gx-custom-badge gx-custom-badge-" + status + (className ? " " + className  : "")} {...res}>{children}</Badge>
  )
}

CustomBadge.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(["success", "error", "default", "warning"]),
  visible: PropTypes.bool,
}

CustomBadge.defaultProps = {
  status: "default",
  visible: true,
}

export default CustomBadge;
