import React, { memo, useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import {
  getMe,
  showAuthLoader,
  userTokenSignIn,
  setInitUrl
} from "../../appRedux/slices/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "../../appRedux/slices/ThemeSettings";
import CircularProgress from "../../components/CircularProgress";
// import Pricing from "../../routes/Pricing";
import useSubPlan from "../../hooks/useSubPlan";
import { Suspense } from "react";
import LazyLoad from "../../components/LazyLoad";
import config from "../../config";

const Pricing = lazy(() => import("../../routes/Pricing"));
const PublicPricing = lazy(() => import("../../routes/PublicPricing"));

const RestrictedRoute = ({ component: Component, location, ...rest }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const loader = useSelector(({ auth }) => auth.loader);

  // Check trial is expired or not
  // If trial is expired go to pricing page.
  const { shouldGoToPricing } = useSubPlan();

  return (
    <Route
      {...rest}
      render={props => {
        if (loader) {
          return <CircularProgress className="loading" />;
        }

        if (isAuthenticated) {
          if (shouldGoToPricing) {
            return (
              <Redirect
                to={{
                  pathname: "/pricing",
                  state: { from: location }
                }}
              />
            );
          }
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

const App = props => {
  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(
    ({ themeSettings }) => themeSettings
  );
  const initURL = useSelector(({ auth }) => auth.initURL);
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const loader = useSelector(({ auth }) => auth.loader);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === "") {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get("theme")));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get("nav-style")));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get("layout-type")));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });

  const setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  // Login by token
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("loginToken");
    if (token) {
      dispatch(showAuthLoader());
      dispatch(userTokenSignIn(token));
    }
  }, [dispatch]);

  // Get me
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("loginToken");
    if (!token) {
      const chargeId = urlParams.get("charge_id");
      let params;
      if (chargeId) {
        params = { chargeId };
      }
      dispatch(getMe(true, params));
    }
  }, [dispatch]);

  useEffect(() => {
    if (loader) {
      return;
    }
     // If not login, but path is pricing with discount code, should go to shopify app store
     console.log("on url:", location.pathname);
     if (!isAuthenticated && location.pathname == "/pricing" && location.search.includes("discountCode=")) {
       window.location.href = config.shopifyAppStoreFlashSearchUrl;
       return;
     }
  }, [isAuthenticated, loader, location.pathname]);

  useEffect(() => {
    if (
      isAuthenticated &&
      (location.pathname === "/" || location.pathname === "/signin")
    ) {
      if (initURL === "" || initURL === "/" || initURL === "/signin") {
        history.push("/home");
      } else {
        history.push(initURL);
      }
    }
  }, [history, initURL, isAuthenticated, location.pathname]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Suspense fallback={<LazyLoad />}>
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/public/pricing" component={PublicPricing} />
            <RestrictedRoute
              path={`${match.url}`}
              location={location}
              component={MainApp}
            />
          </Switch>
        </Suspense>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
