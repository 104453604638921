import {createSlice} from "@reduxjs/toolkit";

const INIT_STATE = {
  error: "",
  loading: false,
  partialLoading: false,
  itemLoading: false,
  item2Loading: false,
  message: '',
  itemLoader: {}
};

const DEFAULT_ERROR_MESSAGE = "An Error occurred, please refresh and try again.";

const getErrorMsg = (payload) => {
  return payload || DEFAULT_ERROR_MESSAGE;
};

const slice = createSlice({
  name: "common",
  initialState: INIT_STATE,
  reducers: {
    fetchItemIdStart(state, action) {
      const id = action.payload;
      return {...state, error: '', message: '', itemLoader: {...state.itemLoader, [id]: true}};
    },

    fetchItemIdSuccess(state, action) {
      const id = action.payload;
      return {...state, error: '', message: '', itemLoader: {...state.itemLoader, [id]: false}};
    },

    fetchItemIdError: {
      reducer(state, action) {
        const {id, errorMessage} = action.payload;
        return {...state, itemLoader: {...state.itemLoader, [id]: false}, error: getErrorMsg(errorMessage), message: ""};
      },

      prepare(id, errorMessage) {
        return {payload: {id, errorMessage}};
      }
    },

    fetchStart(state) {
      return {...state, error: '', message: '', loading: true};
    },

    fetchSuccess(state) {
      return {...state, error: '', message: '', loading: false};
    },

    fetchError(state, action) {
      return {...state, loading: false, error: getErrorMsg(action.payload), message: ''};
    },

    // Partial
    fetchPartialStart(state) {
      return {...state, error: '', message: '', partialLoading: true};
    },

    fetchPartialSuccess(state) {
      return {...state, error: '', message: '', partialLoading: false};
    },

    fetchPartialError(state, action) {
      return {...state, partialLoading: false, error: getErrorMsg(action.payload), message: ''};
    },

    // Fetch item
    fetchItemStart(state) {
      return {...state, error: '', message: '', itemLoading: true};
    },

    fetchItemSuccess(state) {
      return {...state, error: '', message: '', itemLoading: false};
    },

    fetchItemError(state, action) {
      return {...state, itemLoading: false, error: getErrorMsg(action.payload), message: ''};
    },

    // Fetch item 2
    fetchItem2Start(state) {
      return {...state, error: '', message: '', item2Loading: true};
    },

    fetchItem2Success(state) {
      return {...state, error: '', message: '', item2Loading: false};
    },

    fetchItem2Error(state, action) {
      return {...state, item2Loading: false, error: getErrorMsg(action.payload), message: ''};
    },

    // show messages
    showMessage(state, action) {
      return {...state, error: '', message: action.payload, loading: false};
    },

    showErrorMessage(state, action) {
      return {...state, loading: false, error: getErrorMsg(action.payload), message: ''};
    },

    // hide message
    hideMessage(state) {
      return {...state, loading: false, error: '', message: ''};
    }
  }
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  fetchPartialStart,
  fetchPartialSuccess,
  fetchPartialError,
  fetchItemStart,
  fetchItemSuccess,
  fetchItemError,
  fetchItem2Start,
  fetchItem2Success,
  fetchItem2Error,
  fetchItemIdStart,
  fetchItemIdSuccess,
  fetchItemIdError,
  showMessage,
  showErrorMessage,
  hideMessage,
} = slice.actions;

export default slice.reducer;
