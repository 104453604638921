
// Public token is token using for /search API

// Save public token to local storage
export const savePublicToken = (token) => {
    localStorage.setItem("FlashSearchPublicToken", token);
}

// get public token from local storage
export const getPublicToken = () => {
    return localStorage.getItem("FlashSearchPublicToken");
}