import { Button } from "antd";
import React from "react";
import PropTypes from "prop-types";
import usePricing from "../../routes/hooks/usePricing";

const UpgradePlanButton = ({ className, type }) => {
  const {goToPricingPage} = usePricing();

  const isUpgrade = type === "upgrade";
  const text = isUpgrade ? "Upgrade" : "Change plan";
  const handleClick = () => {
    goToPricingPage();
  };
  return (
    <Button
      type={!isUpgrade ? "primary" : undefined}
      className={
        (isUpgrade ? "gx-upgrade-button" : "") +
        (className ? " " + className : "")
      }
      onClick={handleClick}
      data-testid="upgrade-plan-btn"
    >
      {text}
    </Button>
  );
};

UpgradePlanButton.defaultProps = {
  type: "upgrade"
};

UpgradePlanButton.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(["upgrade", "change"]),
  className: PropTypes.string
};

export default UpgradePlanButton;
