import axios from "axios";
import config from "../config"
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

// Sign in
const signInWithToken = (token) => {
  const url = `${ENDPOINT}/login`;
  return axios({
    url,
    data: {
      loginToken: token
    },
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

const signIn = (email, password) => {
  const url = `${ENDPOINT}/login`;
  return axios({
    url,
    data: {
      email,
      password
    },
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

// Get me
const getMe = (params) => {
  let paramsStr = ""
  if (params) {
    paramsStr = "?" + encodeQueryData(params);
  }
  const url = `${ENDPOINT}/auth/me${paramsStr}`;
  return axios({
    url,
    method: 'get',
    withCredentials: true,
  });
};

// Check first indexing or not
const firstIndexing = () => {
  const url = `${ENDPOINT}/auth/firstIndexing`;
  return axios({
    url,
    method: 'get',
    withCredentials: true,
  })
};

// Update store
const updateStore = (store) => {
  const url = `${ENDPOINT}/auth/stores`;
  const {firstSetup} = store;
  return axios({
    url,
    data: {
      firstSetup,
    },
    method: 'put',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

// First setup done
const firstSetupDone = () => {
  const url = `${ENDPOINT}/auth/widgets/firstSetupDone`;
  return axios({
    url,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

const authApi = {
  signInWithToken,
  signIn,
  getMe,
  firstIndexing,
  updateStore,
  firstSetupDone,
};

export default authApi;
