import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { INTERVAL_MONTHLY, INTERVAL_ANNUALLY } from "../routes/constants";

const useSubPlan = () => {
  const planId = useSelector(({ auth }) => auth.authUser?.subscriptionPlan?.id);

  const planName = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.name
  );

  // const planStatus = "ACTIVE".toLowerCase();
  const planStatus = useSelector(({ auth }) => {
    const status = auth.authUser?.subscriptionPlan?.status;
    if (status) {
      return status.toLowerCase();
    }
    return "";
  });

  const planInterval = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.interval
  );

  const planIntervalName = useSelector(({ auth }) => {
    const interval = auth.authUser?.subscriptionPlan?.interval;
    if (interval === INTERVAL_MONTHLY) {
      return "Monthly";
    } else if (interval === INTERVAL_ANNUALLY) {
      return "Annually";
    }
  });

  // const planPrice = 19;
  const planPrice = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.price
  );
  const trialDaysLeft = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.trialDaysLeft
  );
  const totalTrialDays = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.totalTrialDays
  );

  const storeProductCount = useSelector(
    ({ auth }) => auth.authUser?.indexedProductCount
  );

  const planProductCount = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.planProductCount
  );

  const [productCountPercent, setProductCountPercent] = useState(0);
  useEffect(() => {
    if (planProductCount !== undefined && storeProductCount !== undefined) {
      setProductCountPercent((storeProductCount / planProductCount) * 100);
    }
  }, [planProductCount, storeProductCount]);

  const planExceeded = useSelector(
    ({ auth }) =>
      auth.authUser?.subscriptionPlan?.exceeded?.productCountExceeded
  );

  // const isDevPlan = false;
  const isDevPlan = useSelector(
    ({ auth }) => auth.authUser?.subscriptionPlan?.isDevPlan
  );

  // App disabled after plan exceeded
  const appDisabledAfterPlanExceeded = useSelector(
    ({ auth }) => auth.authUser?.appDisabledAfterPlanExceededEnd
  );

  const [isPremiumPlan, setIsPremiumPlan] = useState(true);
  useEffect(() => {
    setIsPremiumPlan(!!planName && !isDevPlan && planName !== "Trial");
  }, [isDevPlan, planName]);

  const [trialDaysLeftPercent, setTrialDaysLeftPercent] = useState(0);
  useEffect(() => {
    if (trialDaysLeft !== undefined && totalTrialDays !== undefined) {
      setTrialDaysLeftPercent((trialDaysLeft / totalTrialDays) * 100);
    }
  }, [totalTrialDays, trialDaysLeft]);

  const [isTrialExpired, setIsTrialExpired] = useState(true);
  useEffect(() => {
    setIsTrialExpired(!isDevPlan && trialDaysLeft === 0);
  }, [isDevPlan, trialDaysLeft]);

  const [shouldGoToPricing, setShouldGoToPricing] = useState(false);
  useEffect(() => {
    setShouldGoToPricing(isTrialExpired || appDisabledAfterPlanExceeded);
  }, [appDisabledAfterPlanExceeded, isTrialExpired]);

  return {
    planId,
    planName,
    planStatus,
    planInterval,
    planIntervalName,
    planPrice,
    totalTrialDays,
    trialDaysLeft,
    trialDaysLeftPercent,
    storeProductCount,
    planProductCount,
    productCountPercent,
    planExceeded,
    isDevPlan,
    isPremiumPlan,
    isTrialExpired,
    shouldGoToPricing
  };
};

export default useSubPlan;
