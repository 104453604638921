import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {DEFAULT_SEARCH_PRODUCTS_NEXT_TOKEN} from "../../constants/SearchProducts";
import {updateSelectedItems, updateSelectedItem, updateItemEnableStatus} from "./utils";

const removeProductItem = (products, product) => {
  return products.filter(item => item.id !== product.id);
};

const INIT_SEARCH_PRODUCTS = {
  totalSearchResults: 0,
  searchTerm: '',
  searchedProducts: [],
  nextToken: DEFAULT_SEARCH_PRODUCTS_NEXT_TOKEN,
  searchDone: false,
  firstGetAllLoading: false,
};

const INIT_CREATE_A_PIN = {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_PIN = {
  pins: [],
  totalPins: 0,
  totalCurrentPins: 0,
  pinSearchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
};

const INIT_COMMON = {
  selectedPinsCount: 0,
  placeholderIndex: 0,
  error: '',
};

const INIT_UPDATE_A_PIN = {
  showEditAItemModal: false,
  doPinSearch: false,
  currentEditPin: {},
};

const INIT_STATE = {
  ...INIT_SEARCH_PRODUCTS,
  // Create a pin
  ...INIT_CREATE_A_PIN,
  // Get all pins
  ...INIT_GET_ALL_PIN,
  // Update a pin
  ...INIT_UPDATE_A_PIN,
  // Common
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "pin",
  initialState: INIT_STATE,
  reducers: {
    // Create a pin
    showCreateAPinModal(state) {
      return {
        ...state,
        ...INIT_SEARCH_PRODUCTS,
        ...INIT_CREATE_A_PIN,
        ...INIT_COMMON,
        showCreateAItemModal: true,
      }
    },

    hideCreateAPinModal(state) {
      state.showCreateAItemModal = false;
    },

    changeSearchTermForCreate(state, action) {
      return {
        ...state,
        ...INIT_SEARCH_PRODUCTS,
        ...INIT_CREATE_A_PIN,
        ...INIT_COMMON,
        showCreateAItemModal: true,
      }
    },

    changeSearchTermForUpdate(state, action) {
      return {
        ...state,
        ...INIT_SEARCH_PRODUCTS,
        ...INIT_UPDATE_A_PIN,
        ...INIT_COMMON,
        showEditAItemModal: true,
        currentEditPin: {...state.currentEditPin, searchTerm: "", pinnedProducts: []}
      }
    },

    createAPin() {
    },

    createAPinSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_PIN,
        ...INIT_COMMON,
      }
    },

    searchProducts: {
      reducer(state) {
        return state;
      },
      prepare(bodyData, metadata) {
        return {payload: {bodyData, metadata}}
      }
    },

    searchProductsSuccess: {
      reducer(state, action) {
        const {data, metadata} = action.payload;
        let {total, query, products, nextToken} = data;

        // Push place holder item to the top
        products.unshift({id: "PLACE_HOLDER"});

        // If pin search for edit a modal
        if (metadata !== undefined) {
          const {pinnedProducts} = metadata;
          if (pinnedProducts !== undefined) {
            pinnedProducts.slice().reverse().forEach((item) => {
              products = removeProductItem(products, item);
              products.unshift(item);
            });
          }
        }

        return {
          ...state,
          totalSearchResults: total,
          searchTerm: query,
          searchedProducts: products,
          nextToken,
          searchDone: true,
        }
      },

      prepare(data, metadata) {
        return {payload: {data, metadata}}
      }
    },

    searchProductsLoadMore: {
      reducer(state) {
        return state;
      },

      prepare(bodyData, metadata) {
        return {payload: {bodyData, metadata}};
      }
    },

    searchProductsLoadMoreSuccess: {
      reducer(state, action) {
        const {data, metadata} = action.payload;
        let {total, query, products, nextToken} = data;

        products = [...state.searchedProducts, ...products];

        // If pin search for edit a modal
        if (metadata !== undefined) {
          const {pinnedProducts} = metadata;
          if (pinnedProducts !== undefined) {
            pinnedProducts.slice().reverse().forEach((item) => {
              products = removeProductItem(products, item);
              products.unshift(item);
            });
          }
        }

        return {
          ...state,
          totalSearchResults: total,
          searchTerm: query,
          searchedProducts: products,
          nextToken,
          searchDone: true,
        }
      },

      prepare(data, metadata) {
        return {payload: {data, metadata}};
      }
    },

    increasePlaceholderIndex(state, action) {
      state.placeholderIndex = state.placeholderIndex + action.payload
    },

    updateProductPriorities(state, action) {
      state.searchedProducts = action.payload;
    },

    // Get all pins
    getAllPins: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
          params.search = params.search || '';
        }

        return {
          payload: params
        }
      }
    },

    getAllPinsSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all pins
        const pins = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          pins,
          totalCurrentPins: total,
          selectedPinsCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalPins = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}};
      },
    },

    setFirstGetAllPinsLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    updatePinSearchTerm(state, action) {
      state.pinSearchTerm = action.payload;
    },

    setDidFirstGetAllPinsRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    // Update a pin
    updateAPin: {
      reducer(state) {return state},
      prepare(pin, metadata) {
        return {payload: {pin, metadata}}
      }
    },

    updateAPinSuccess(state) {
      return {
        ...state,
        ...INIT_SEARCH_PRODUCTS,
        ...INIT_UPDATE_A_PIN,
        ...INIT_COMMON,
      };
    },

    // Update status of a pin
    updateStatusAPin() {},

    updateStatusAPinSuccess(state, action) {
      state.pins = updateItemEnableStatus(action.payload, state.pins);
    },

    showEditAPinModal(state, action) {
      const {searchTerm, pinnedProducts} = action.payload;
      return {
        ...state,
        searchTerm,
        placeholderIndex: pinnedProducts.length,
        currentEditPin: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditAPinModal(state) {
      return {
        ...state,
        ...INIT_SEARCH_PRODUCTS,
        ...INIT_UPDATE_A_PIN,
        ...INIT_COMMON,
      }
    },

    updateCurrentEditPin(state, action) {

      state.currentEditPin = action.payload;
      // return {
      //   ...state,
      //   currentEditPin: {...action.payload},
      // }
    },

    // Delete a pin
    deleteAPin() {
    },

    // Delete many pins
    deleteManyPins() {
    },

    deleteManyPinsSuccess(state) {
      state.selectedPinsCount = 0;
    },

    // Common
    updateSelectedPin(state, action) {
      state.selectedPinsCount = updateSelectedItem(state.pins, action.payload);
    },

    updateAllPinsToSelected(state) {
      // Set selected=true for all pins
      updateSelectedItems(state.pins, true);
      state.selectedPinsCount = state.pins.length;
    },

    updateAllPinsToUnSelected(state) {
      updateSelectedItems(state.pins, false);
      state.selectedPinsCount = 0;
    }
  }
});

export const {
  showCreateAPinModal,
  hideCreateAPinModal,
  changeSearchTermForCreate,
  changeSearchTermForUpdate,
  createAPin,
  createAPinSuccess,
  searchProducts,
  searchProductsSuccess,
  searchProductsLoadMore,
  searchProductsLoadMoreSuccess,
  increasePlaceholderIndex,
  updateProductPriorities,
  getAllPins,
  getAllPinsSuccess,
  setFirstGetAllPinsLoading,
  updatePinSearchTerm,
  setDidFirstGetAllPinsRequest,
  updateAPin,
  updateAPinSuccess,
  updateStatusAPin,
  updateStatusAPinSuccess,
  showEditAPinModal,
  hideEditAPinModal,
  updateCurrentEditPin,
  deleteAPin,
  deleteManyPins,
  deleteManyPinsSuccess,
  updateSelectedPin,
  updateAllPinsToSelected,
  updateAllPinsToUnSelected
} = slice.actions;

export default slice.reducer;
