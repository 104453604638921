import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchItemIdError, fetchItemIdStart, fetchItemIdSuccess, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createAFilterGroup,
  createAFilterGroupSuccess,
  getAllFilterGroups,
  getAllFilterGroupsSuccess,
  deleteAFilterGroup,
  deleteManyFilterGroups,
  deleteManyFilterGroupsSuccess,
  updateAFilterGroup,
  updateAFilterGroupSuccess, updateStatusAFilterGroupSuccess, updateStatusAFilterGroup,
} from "../slices/FilterGroup";
import filterGroupAPI from "../../services/filterGroup";

// Create a item
function* createAFilterGroupRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(filterGroupAPI.createOne, item);
    yield put(createAFilterGroupSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Filter group created"));
    yield put(getAllFilterGroups());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAFilterGroupSaga() {
  yield takeEvery(createAFilterGroup, createAFilterGroupRequest);
}

// Get all items
function* getAllFilterGroupsRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(filterGroupAPI.getAll, payload);
    yield put(getAllFilterGroupsSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllFilterGroupsSaga() {
  yield takeEvery(getAllFilterGroups, getAllFilterGroupsRequest)
}

// Update a item
function* updateAFilterGroupRequest({payload}) {
  const {filterGroup, metadata} = payload;
  const {offset, searchTerm} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(filterGroupAPI.updateOne, filterGroup);
    yield put(updateAFilterGroupSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Filter group updated"));
    yield put(getAllFilterGroups({offset, search: searchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateAFilterGroupSaga() {
  yield takeEvery(updateAFilterGroup, updateAFilterGroupRequest);
}

// Update status of a item
function* updateStatusAFilterGroupRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(filterGroupAPI.updateOne, item);
    yield put(updateStatusAFilterGroupSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Filter group ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id));
  }
}

function* updateStatusAFilterGroupSaga() {
  yield takeEvery(updateStatusAFilterGroup, updateStatusAFilterGroupRequest);
}

// Delete a item
function* deleteAFilterGroupRequest({payload}) {
  try {
    const filterGroup = payload;
    yield put(fetchPartialStart());
    yield call(filterGroupAPI.deleteOne, filterGroup.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Filter group deleted"));
    yield put(getAllFilterGroups());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAFilterGroupSaga() {
  yield takeEvery(deleteAFilterGroup, deleteAFilterGroupRequest);
}

// Delete many items
function* deleteManyFilterGroupsRequest({payload}) {
  try {
    const ids = payload.map(p => p.id);
    yield put(fetchPartialStart());
    yield call(filterGroupAPI.deleteMany, ids);
    yield put(deleteManyFilterGroupsSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Selected filter groups deleted"));
    yield put(getAllFilterGroups());

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManyFilterGroupsSaga() {
  yield takeEvery(deleteManyFilterGroups, deleteManyFilterGroupsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createAFilterGroupSaga),
    fork(getAllFilterGroupsSaga),
    fork(deleteAFilterGroupSaga),
    fork(updateAFilterGroupSaga),
    fork(updateStatusAFilterGroupSaga),
    fork(deleteManyFilterGroupsSaga),
  ]);
}
