import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const createOne = (pin) => {
  const url = `${ENDPOINT}/auth/filterGroups`;
  return axios({
    url,
    data: pin,
    method: "post",
    withCredentials: true,
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/filterGroups?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateOne = (filterGroup) => {
  const url = `${ENDPOINT}/auth/filterGroups/${filterGroup.id}`;
  const {
    name, collections, vendors, type, enable,
  } = filterGroup;
  return axios({
    url,
    method: "put",
    data: {
      name,
      type,
      vendors,
      collections,
      enable,
    },
    withCredentials: true,
  })
};

const deleteOne = (pinId) => {
  const url = `${ENDPOINT}/auth/filterGroups/${pinId}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
  })
};

const deleteMany = (pinIds) => {
  const url = `${ENDPOINT}/auth/filterGroups/bulk_delete`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: pinIds,
    },
    withCredentials: true,
  })
};

const filterGroupAPI = {
  createOne,
  getAll,
  updateOne,
  deleteOne,
  deleteMany,
};

export default filterGroupAPI;

