import React from "react";
import { Alert, Button } from "antd";
import PropTypes from "prop-types";
import { buildTestIdFromStr } from "../../util/resourceComponent";

const Banner = ({
  title,
  description,
  status,
  action,
  secondaryAction,
  onDismiss,
  className,
  prefixTestId
}) => {
  const buildTestId = str => {
    return buildTestIdFromStr(prefixTestId, "banner", str);
  };

  const descriptionMarkup = description ? (
    <>
      <p className="gx-mb-0">{description}</p>
      <div className="gx-mt-3">
        {action ? (
          <Button
            ghost
            onClick={action.onAction}
            className="gx-mb-0"
            data-testid={buildTestId(action.content)}
          >
            {action.content}
          </Button>
        ) : null}
        {secondaryAction ? (
          <Button
            type="link"
            onClick={secondaryAction.onAction}
            className="gx-mb-0"
            data-testid={buildTestId(secondaryAction.content)}
          >
            {secondaryAction.content}
          </Button>
        ) : null}
      </div>
    </>
  ) : null;

  return (
    <Alert
      // banner
      showIcon
      message={title}
      description={descriptionMarkup}
      type={status}
      onClose={onDismiss}
      className={"gx-mb-0" + (className ? " " + className : "")}
    />
  );
};

export default Banner;

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  status: PropTypes.oneOf(["success", "info", "warning", "error"]),
  action: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func
  }),
  secondaryAction: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func
  }),
  onDismiss: PropTypes.func,
  prefixTestId: PropTypes.string
};
