import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchItemIdStart,
  fetchItemIdSuccess,
  fetchItemIdError,
  fetchError, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createAMetafieldSearchField,
  createAMetafieldSearchFieldSuccess,
  getAllMetafieldSearchFields,
  getAllMetafieldSearchFieldsSuccess,
  deleteAMetafieldSearchField,
  updateAMetafieldSearchField,
  updateAMetafieldSearchFieldSuccess,
  updateStatusAMetafieldSearchField,
  updateStatusAMetafieldSearchFieldSuccess,
} from "../slices/MetafieldSearchField";
import metafieldSearchFieldAPI from "../../services/metafieldSearchField";
import {METAFIELD_SEARCH_FIELD_LOADING_ID} from "../../constants/loadingIds";
import {showMfSfSyncBanner} from "../slices/MetafieldSearchField";

// Create a metafield search field
function* createAMetafieldSearchFieldRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(metafieldSearchFieldAPI.createOne, item);
    yield put(createAMetafieldSearchFieldSuccess());
    yield put(showMfSfSyncBanner());
    yield put(fetchSuccess());
    yield put(showMessage("Search field by metafield created"));
    yield put(getAllMetafieldSearchFields());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAMetafieldSearchFieldSaga() {
  yield takeEvery(createAMetafieldSearchField, createAMetafieldSearchFieldRequest);
}

// Get all search field by metafield
function* getAllMetafieldSearchFieldsRequest({payload}) {
  try {
    yield put(fetchItemIdStart(METAFIELD_SEARCH_FIELD_LOADING_ID));
    const {data} = yield call(metafieldSearchFieldAPI.getAll, payload);
    yield put(getAllMetafieldSearchFieldsSuccess(data));
    yield put(fetchItemIdSuccess(METAFIELD_SEARCH_FIELD_LOADING_ID));
  } catch (error) {
    yield put(fetchItemIdError(METAFIELD_SEARCH_FIELD_LOADING_ID));
  }
}

function* getAllMetafieldSearchFieldsSaga() {
  yield takeEvery(getAllMetafieldSearchFields, getAllMetafieldSearchFieldsRequest)
}

// Update a metafield search field
function* updateAMetafieldSearchFieldRequest({payload}) {
  const {searchField, metadata} = payload;
  const {offset} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(metafieldSearchFieldAPI.updateOne, searchField);
    yield put(updateAMetafieldSearchFieldSuccess());
    yield put(showMfSfSyncBanner());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Search field my metafield updated"));
    yield put(getAllMetafieldSearchFields({offset}));
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* updateAMetafieldSearchFieldSaga() {
  yield takeEvery(updateAMetafieldSearchField, updateAMetafieldSearchFieldRequest);
}

// Update status a metafield search field
function* updateStatusAMetafieldSearchFieldRequest({payload}) {
  const item = payload;
  const id = item.id;
  try {
    yield put(fetchItemIdStart(id));
    yield call(metafieldSearchFieldAPI.updateOne, item);
    yield put(updateStatusAMetafieldSearchFieldSuccess(item));
    yield put(showMfSfSyncBanner());
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Search field my metafield ${item.enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id))
  }
}

function* updateStatusAMetafieldSearchFieldSaga() {
  yield takeEvery(updateStatusAMetafieldSearchField, updateStatusAMetafieldSearchFieldRequest);
}

// Delete a search field by metafield
function* deleteAMetafieldSearchFieldRequest({payload}) {
  try {
    const searchField = payload;
    yield put(fetchPartialStart());
    yield call(metafieldSearchFieldAPI.deleteOne, searchField.id);
    yield put(showMfSfSyncBanner());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Search field by metafield deleted"));
    yield put(getAllMetafieldSearchFields());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAMetafieldSearchFieldSaga() {
  yield takeEvery(deleteAMetafieldSearchField, deleteAMetafieldSearchFieldRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createAMetafieldSearchFieldSaga),
    fork(getAllMetafieldSearchFieldsSaga),
    fork(deleteAMetafieldSearchFieldSaga),
    fork(updateAMetafieldSearchFieldSaga),
    fork(updateStatusAMetafieldSearchFieldSaga),
  ]);
}
