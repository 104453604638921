export const makeCreateSetupThemeReqData = (shopifyThemes, formData) => {
  const theme = shopifyThemes.filter(theme => theme.id === formData.theme)[0];
  return {
    theme: {
      ...theme,
      layoutType: formData.layoutType,
      isOs2: formData.isOs2
    },
    supportedThemeInfo: formData?.supportedThemeInfo
  };
};
