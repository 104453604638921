import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchItemIdError, fetchItemIdStart, fetchItemIdSuccess, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createASynonyms,
  createASynonymsSuccess,
  getAllSynonymss,
  getAllSynonymssSuccess,
  updateASynonyms,
  updateASynonymsSuccess,
  deleteASynonyms,
  deleteManySynonymss,
  deleteManySynonymssSuccess, updateStatusASynonymsSuccess, updateStatusASynonyms,
} from "../slices/Synonyms";
import synonymsAPI from "../../services/synonyms";

// Create a synonyms
function* createASynonymsRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(synonymsAPI.createOne, item);
    yield put(createASynonymsSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Synonyms created"));
    yield put(getAllSynonymss());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createASynonymsSaga() {
  yield takeEvery(createASynonyms, createASynonymsRequest);
}

// Get all synonymss
function* getAllSynonymssRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(synonymsAPI.getAll, payload);
    yield put(getAllSynonymssSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllSynonymssSaga() {
  yield takeEvery(getAllSynonymss, getAllSynonymssRequest)
}

// Update a synonyms
function* updateASynonymsRequest({payload}) {
  const {synonyms, metadata} = payload;
  const {offset, searchTerm} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(synonymsAPI.updateOne, synonyms);
    yield put(updateASynonymsSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Synonyms updated"));
    yield put(getAllSynonymss({offset, search: searchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateASynonymsSaga() {
  yield takeEvery(updateASynonyms, updateASynonymsRequest);
}

// Update status of a synonyms
function* updateStatusASynonymsRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(synonymsAPI.updateOne, item);
    yield put(updateStatusASynonymsSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Synonyms ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError());
  }
}

function* updateStatusASynonymsSaga() {
  yield takeEvery(updateStatusASynonyms, updateStatusASynonymsRequest);
}

// Delete a synonyms
function* deleteASynonymsRequest({payload}) {
  try {
    const synonyms = payload;
    yield put(fetchPartialStart());
    yield call(synonymsAPI.deleteOne, synonyms.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Synonyms deleted"));
    yield put(getAllSynonymss());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteASynonymsSaga() {
  yield takeEvery(deleteASynonyms, deleteASynonymsRequest);
}

// Delete many synonymss
function* deleteManySynonymssRequest({payload}) {
  try {
    const ids = payload.map(p => p.id);
    yield put(fetchPartialStart());
    yield call(synonymsAPI.deleteMany, ids);
    yield put(deleteManySynonymssSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Selected synonyms deleted"));
    yield put(getAllSynonymss());

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManySynonymssSaga() {
  yield takeEvery(deleteManySynonymss, deleteManySynonymssRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createASynonymsSaga),
    fork(getAllSynonymssSaga),
    fork(deleteASynonymsSaga),
    fork(updateASynonymsSaga),
    fork(updateStatusASynonymsSaga),
    fork(deleteManySynonymssSaga),
  ]);
}
