import { setup } from "../../appRedux/slices/Theme";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import {
  GET_ALL_SETUP_THEMES_QK,
  GET_SETUP_THEME_QK
} from "../hooks/theme/constants";
import { makeCreateSetupThemeReqData } from "./utils";

const useHandleSetupTheme = (form, { requestFunc } = {}) => {
  const { validateFields } = form;

  const dispatch = useDispatch();
  const shopifyThemes = useSelector(
    ({ shopifyTheme }) => shopifyTheme.shopifyThemes
  );

  const queryClient = useQueryClient();

  return e => {
    e.preventDefault();
    validateFields().then(item => {
      const reqData = makeCreateSetupThemeReqData(shopifyThemes, item);
      if (!requestFunc) {
        dispatch(setup(reqData));
      } else {
        requestFunc(reqData);
      }
      // Clear all cache of react-query for get all setup themes
      queryClient.invalidateQueries(GET_ALL_SETUP_THEMES_QK);
      queryClient.invalidateQueries(GET_SETUP_THEME_QK);
    });
  };
};

export default useHandleSetupTheme;
