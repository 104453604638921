import {createSlice} from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {processParamsForGetAllItems, updateSelectedItems, updateSelectedItem, updateItemEnableStatus} from "./utils";

const INIT_CREATE_A_ITEM = {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  searchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
  firstGetAllLoading: false,
};

const INIT_COMMON = {
  selectedItemCount: 0,
  error: '',
  showEmptyMetafield: false,
  selectedFieldType: null,
  fieldsChanged: {},
};

const INIT_UPDATE_A_ITEM = {
  showEditAItemModal: false,
  currentEditItem: {
    conditions: [
      {
        "action": "BOOST",
        "value": {
          "min": 0,
          "max": 0,
        },
        "values": [],
      }
    ],
  },
};

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "merchandising",
  initialState: INIT_STATE,
  reducers: {
    // Create a merchandising
    showCreateAMerchandisingModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        ...INIT_COMMON,
        showCreateAItemModal: true,
        currentEditItem: {
          conditions: [
            {
              "action": "BOOST",
              "value": {
                "min": 0,
                "max": 0,
              },
              "values": [],
            }
          ],
        },
      }
    },

    hideCreateAMerchandisingModal(state) {
      return {
        ...state,
        showCreateAItemModal: false,
      }
    },

    createAMerchandising() {
    },

    createAMerchandisingSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    getAllMerchandisings: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        return {
          payload: processParamsForGetAllItems(params),
        }
      }
    },

    getAllMerchandisingsSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all merchandisings
        const items = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          items,
          totalCurrentItems: total,
          selectedItemCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalItems = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}};
      }
    },

    setFirstGetAllMerchandisingsLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    setMerchandisingSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setDidFirstGetAllMerchandisingRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    // Update a merchandising
    updateAMerchandising: {
      reducer(state) {
        return {
          ...state,
          ...INIT_UPDATE_A_ITEM,
          ...INIT_COMMON,
        }
      },
      prepare(merchandising, metadata) {
        return {
          payload: {
            merchandising,
            metadata,
          }
        }
      }
    },

    updateAMerchandisingSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    updateStatusAMerchandising() {
    },

    updateStatusAMerchandisingSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showEditAMerchandisingModal(state, action) {
      const item = cloneDeep(action.payload);
      if (item) {
        item.conditions = item.conditions.map(con => {
          // Field
          con.field.key = con.field.value;

          // Values
          if (con.values) {
            con.values = con.values.map(val => {
              return {...val, key: val.value};
            });
          }

          // Date value
          if (con.valueDate) {
            con.valueDate = [moment.unix(con.valueDate.fromDate), moment.unix(con.valueDate.toDate)];
          }

          return con;
        });
      }
      return {
        ...state,
        currentEditItem: item,
        showEditAItemModal: true,
      }
    },

    hideEditAMerchandisingModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
        ...INIT_COMMON,
      }
    },

    setCurrentEditMerchandising(state, action) {
      state.currentEditItem = action.payload;
    },

    addInitConditionToCurrentEditMerchandising(state) {
      const temp = [...state.currentEditItem.conditions];
      temp.push(
        {
          "action": "BOOST",
          "value": {
            "min": 0,
            "max": 0,
          },
          "values": [],
        }
      );
      return {
        ...state,
        currentEditItem: {
          ...state.currentEditItem,
          conditions: temp,
        },
      }
    },

    // Delete merchandising
    deleteAMerchandising() {
    },

    // Delete many merchandisings
    deleteManyMerchandisings() {
    },

    deleteManyMerchandisingsSuccess(state) {
      state.selectedItemCount = 0;
    },

    setMerchandisingConditionFieldChanged: {
      reducer(state, action) {
        const temp = {...state.fieldsChanged};
        temp[action.payload.index] = action.payload.changed;

        return {
          ...state,
          fieldsChanged: temp,
        }
      },
      prepare(changed, index) {
        return {payload: {changed, index}};
      },
    },

    updateSelectedMerchandising(state, action) {
      state.selectedItemCount = updateSelectedItem(state.items, action.payload);
    },

    updateAllMerchandisingsToSelected(state) {
      updateSelectedItems(state.items, true);
      state.selectedItemCount = state.items.length;
    },

    updateAllMerchandisingsToUnSelected(state) {
      updateSelectedItems(state.items, false);
      state.selectedItemCount = 0;
    },

    setSelectedFieldType(state, action) {
      state.selectedFieldType = action.payload;
    },

    showEmptyMetafieldMsg(state) {
      state.showEmptyMetafield = true;
    },

    hideEmptyMetafieldMsg(state) {
      state.showEmptyMetafield = false;
    }
  }
});

export const {
  showCreateAMerchandisingModal,
  hideCreateAMerchandisingModal,
  createAMerchandising,
  createAMerchandisingSuccess,
  getAllMerchandisings,
  getAllMerchandisingsSuccess,
  setFirstGetAllMerchandisingsLoading,
  setMerchandisingSearchTerm,
  setDidFirstGetAllMerchandisingRequest,
  updateAMerchandising,
  updateAMerchandisingSuccess,
  updateStatusAMerchandising,
  updateStatusAMerchandisingSuccess,
  showEditAMerchandisingModal,
  hideEditAMerchandisingModal,
  setCurrentEditMerchandising,
  addInitConditionToCurrentEditMerchandising,
  deleteAMerchandising,
  deleteManyMerchandisings,
  deleteManyMerchandisingsSuccess,
  setMerchandisingConditionFieldChanged,
  updateSelectedMerchandising,
  updateAllMerchandisingsToSelected,
  updateAllMerchandisingsToUnSelected,
  setSelectedFieldType,
  showEmptyMetafieldMsg,
  hideEmptyMetafieldMsg,
} = slice.actions;

export default slice.reducer;

