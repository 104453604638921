import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import React from "react";
import {Spin} from "antd";

const CustomSpin = ({spinning, fontSize=40, ...res}) => {
  const indicator = <LoadingOutlined style={{fontSize: fontSize}} spin/>;
  return (
    <Spin size="large" indicator={indicator} spinning={spinning} {...res}/>
  )
}

export default CustomSpin;
