import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import filterGroupSagas from "./FilterGroup";
import filterSagas from "./Filter";
import pinSagas from "./Pin";
import shopifyThemeSaga from "./ShopifyTheme";
import merchandisingSaga from "./Merchandising";
import synonymsSaga from "./Synonyms";
import redirectSaga from "./Redirect";
import defaultSearchFieldSaga from "./DefaultSearchField";
import metafieldSearchFieldSaga from "./MetafieldSearchField";
import variantMetafieldSearchFieldSaga from "./VariantMetafieldSearchField";
import themeSetupSaga from "./Theme";
import settingsSaga from "./Settings";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    filterGroupSagas(),
    filterSagas(),
    pinSagas(),
    shopifyThemeSaga(),
    merchandisingSaga(),
    synonymsSaga(),
    redirectSaga(),
    defaultSearchFieldSaga(),
    metafieldSearchFieldSaga(),
    variantMetafieldSearchFieldSaga(),
    themeSetupSaga(),
    settingsSaga(),
  ]);
}
