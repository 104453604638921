import { Alert, Button } from "antd";
import classNames from "classnames";
import CustomLink from "../CustomLink";

const URL =
  "https://apps.shopify.com/ecomposer?utm_source=flashsearch&utm_medium=top-bar-cta";

const STORAGE_KEY = "FlashSearchAdDismissEcomposer";

const TopbarAd = ({ className }) => {
  const cls = classNames("gx-mb-0 gx-mr-3", className);
  const handleClose = () => {
    localStorage.setItem(STORAGE_KEY, true);
  };
  console.log("localStorage.getItem(STORAGE_KEY):", localStorage.getItem(STORAGE_KEY));

  const isDismissed = () => localStorage.getItem(STORAGE_KEY);

  const handleClick = () => {
    window.open(URL, '_blank').focus();
  }

  return !isDismissed() ? (
    <Button type="primary" onClick={handleClick}>
       <span className="gx-mr-1" style={{fontSize: "18px"}}>⚡️</span>Get app to build high-converting pages, FREE plan available 
    </Button>
    // <Alert
    //   className={cls}
    //   message={
    //     <>
    //       Get app to build high-converting pages, FREE plan available.{" "}
    //       <CustomLink
    //         href={URL}
    //         type="normal"
    //         children={<span className="gx-mr-3">Get it now </span>}
    //       />
    //     </>
    //   }
    //   closable
    //   onClose={handleClose}
    // />
  ) : null;
};
export default TopbarAd;
