import {
  Button,
  Col,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  Steps,
  Input,
  Divider,
  Checkbox
} from "antd";
import {
  LAYOUT_TYPE_FILTERS_SIDEBAR,
  LAYOUT_TYPE_HORIZONTAL,
  LAYOUT_TYPE_HORIZONTAL_STYLE_2,
  LAYOUT_TYPE_VERTICAL_LEFT
} from "./constants";
import layoutTypeVerticalImg from "../../assets/images/themeSetup/layout-type-vertical.svg";
import layoutTypeHorizontalImg from "../../assets/images/themeSetup/layout-type-horizontal.svg";
import layoutTypeHorizontalStyle2Img from "../../assets/images/themeSetup/layout-type-horizontal-style-2.svg";
import layoutTypeFiltersSidebarImg from "../../assets/images/themeSetup/layout-type-filters-sidebar.svg";
import React, { useEffect, useState } from "react";
import { getAllShopifyThemes } from "../../appRedux/slices/ShopifyTheme";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import useCheckOs2 from "./useCheckStore2";
import CustomSpin from "../../components/CustomSpin";
import CustomLink from "../../components/CustomLink";
import StepIcon from "./StepIcon";
import {
  SUPPORT_STATUS_FULL,
  SUPPORT_STATUS_COLLECTION,
  SUPPORT_STATUS_SEARCH
} from "./constants";
import useCheckIsSupportedTheme from "./useCheckIsSupportedTheme";
import SkeletonDisplayText from "../../components/SkeletonDisplayText";
import docs from "../../config/docs";

const FormItem = Form.Item;
const Option = Select.Option;
const { Step } = Steps;

const SetupThemeForm = ({
  form,
  formItemLayout,
  handleSetup,
  handleCreateSetupTheme,
  isCreateSetupThemeLoading,
  handleCancel,
  handleDoneInstall,
  isDoneInstallLoading,
  isCancelLoading,
  setupBtnLoading,
  setupBtnText,
  disableCancel
}) => {
  const { getFieldDecorator, setFieldsValue, resetFields } = form;

  const { itemLoading } = useSelector(({ common }) => common);
  const shopifyThemes = useSelector(({ shopifyTheme, theme }) => {
    const setupThemeIds = theme.setupThemes.map(item => item.id);
    return shopifyTheme.shopifyThemes.filter(
      item => !setupThemeIds.includes(item.id)
    );
  });
  const dispatch = useDispatch();

  const handleFocusSelectTheme = () => {
    if (shopifyThemes.length === 0) {
      dispatch(getAllShopifyThemes());
    }
  };

  const selectedTheme = form.getFieldValue("theme");

  const [enableLayoutType, setEnableLayoutType] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  // Enable select layout, save button
  useEffect(() => {
    if (selectedTheme) {
      setEnableLayoutType(true);
      setEnableSaveButton(true);
    }
  }, [selectedTheme]);

  const themeOptions = shopifyThemes
    ? shopifyThemes.map((theme, index) => (
        <Option
          key={index}
          value={theme.id}
          data-testid="theme-form-theme-option"
        >
          {theme.name}
        </Option>
      ))
    : null;

  // Select layout markup
  const selectLayoutMarkup = (
    <FormItem
      {...formItemLayout}
      style={{ display: enableLayoutType ? "flex" : "none" }}
      label={
        <span data-testid="theme-form-layout-label">
          Select a filter layout
        </span>
      }
    >
      {getFieldDecorator("layoutType", {
        initialValue: LAYOUT_TYPE_VERTICAL_LEFT
      })(
        <Radio.Group>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <Radio
                value={LAYOUT_TYPE_VERTICAL_LEFT}
                data-testid="theme-form-layout-vertical-radio"
              >
                <span className="gx-text-center">
                  <div className="gx-mb-2">
                    <img width="300px" src={layoutTypeVerticalImg} alt="" />
                  </div>
                  <div>Vertical layout</div>
                </span>
              </Radio>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="gx-mt-2 gx-mt-md-0"
            >
              <Radio
                value={LAYOUT_TYPE_HORIZONTAL}
                data-testid="theme-form-layout-horizontal-radio"
              >
                <span className="gx-text-center">
                  <div className="gx-mb-2">
                    <img width={"300px"} src={layoutTypeHorizontalImg} alt="" />
                  </div>
                  <div>Horizontal layout</div>
                </span>
              </Radio>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="gx-mt-2 gx-mt-md-0"
            >
              <Radio
                value={LAYOUT_TYPE_HORIZONTAL_STYLE_2}
                data-testid="theme-form-layout-horizontal-style-2-radio"
              >
                <span className="gx-text-center">
                  <div className="gx-mb-2">
                    <img
                      width={"300px"}
                      src={layoutTypeHorizontalStyle2Img}
                      alt=""
                    />
                  </div>
                  <div>Horizontal style 2 layout</div>
                </span>
              </Radio>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className="gx-mt-2 gx-mt-md-0"
            >
              <Radio
                value={LAYOUT_TYPE_FILTERS_SIDEBAR}
                data-testid="theme-form-layout-filters-sidebar-radio"
              >
                <span className="gx-text-center">
                  <div className="gx-mb-2">
                    <img
                      width={"300px"}
                      src={layoutTypeFiltersSidebarImg}
                      alt=""
                    />
                  </div>
                  <div>Filters sidebar layout</div>
                </span>
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      )}
    </FormItem>
  );

  // Steps
  // Check theme support OS 2.0
  const { data: checkOs2Result, isLoading: isCheckOs2Loading } = useCheckOs2(
    selectedTheme
  );
  const supportStatus = checkOs2Result.supportStatus;

  // Set isOs2 from response API check OS 2.0
  useEffect(() => {
    if (checkOs2Result.isOs2) {
      setFieldsValue({
        isOs2: checkOs2Result.isOs2
      });
    }
  }, [checkOs2Result]);

  // Step state
  const [currentStep, setCurrentStep] = useState(0);

  const {
    containsCssEmbedAppBlock,
    containsJsEmbedAppBlock,
    containsSrAppBlockOnCollectionPage,
    containsSrAppBlockOnSearchPage
  } = checkOs2Result;

  // Complete steps status
  const isCompletedStepEnableEmbedAppBlock =
    containsCssEmbedAppBlock && containsJsEmbedAppBlock;
  const isCompletedSetupAppBlocks =
    (supportStatus === SUPPORT_STATUS_FULL &&
      containsSrAppBlockOnCollectionPage &&
      containsSrAppBlockOnSearchPage) ||
    (supportStatus === SUPPORT_STATUS_COLLECTION &&
      containsSrAppBlockOnCollectionPage) ||
    (supportStatus === SUPPORT_STATUS_SEARCH && containsSrAppBlockOnSearchPage);

  // Should disable next step or not
  // Depend on user completed or not
  let disableNextStep = false;
  if (currentStep === 1) {
    disableNextStep = !isCompletedStepEnableEmbedAppBlock;
  } else if (currentStep === 2) {
    disableNextStep = !isCompletedSetupAppBlocks;
  }

  // If select other theme, reset current step
  useEffect(() => {
    if (selectedTheme !== undefined) {
      setCurrentStep(0);
    }
  }, [selectedTheme]);

  // Whether copied public token or not
  const [isCopiedPublicToken, setIsCopiedPublicToken] = useState(false);
  const handleCopyPublicToken = () => {
    setIsCopiedPublicToken(true);
  };

  // Hande for each step
  const handleNextStep = e => {
    // On select layout
    if (currentStep === 0) {
      handleCreateSetupTheme(e);
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const stepSelectLayoutMarkup = <>{selectLayoutMarkup}</>;

  const stepEnableAppEmbedBlocksMarkup = (
    <>
      <p>
        <StepIcon isSuccess={containsCssEmbedAppBlock} />
        Enable CSS embed block by go to{" "}
        <CustomLink
          href={checkOs2Result.cssEmbedDeepLink}
          children={"Theme editor"}
        />
      </p>
      <p>
        <StepIcon isSuccess={containsJsEmbedAppBlock} />
        Enable Javascript embed block by go to{" "}
        <CustomLink
          href={checkOs2Result.jsEmbedDeepLink}
          children={"Theme editor"}
        />
      </p>
    </>
  );

  const stepSetupAppblocksMarkup = (
    <>
      {supportStatus === SUPPORT_STATUS_FULL ||
      supportStatus === SUPPORT_STATUS_COLLECTION ? (
        <p>
          <StepIcon isSuccess={containsSrAppBlockOnCollectionPage} />
          Setup <b>Search results</b> app block on <b>Collection page</b> by
          going to{" "}
          <CustomLink
            href={checkOs2Result.collectionEditorUrl}
            children={"Theme editor"}
          />
        </p>
      ) : null}
      {supportStatus === SUPPORT_STATUS_FULL ||
      supportStatus === SUPPORT_STATUS_SEARCH ? (
        <p>
          <StepIcon isSuccess={containsSrAppBlockOnSearchPage} />
          Setup <b>Search results</b> app block on <b>Search page</b> by going
          to{" "}
          <CustomLink
            href={checkOs2Result.searchEditorUrl}
            children={"Theme editor"}
          />
        </p>
      ) : null}
    </>
  );

  const steps = [
    {
      content: stepSelectLayoutMarkup
    },
    {
      content: stepEnableAppEmbedBlocksMarkup
    },
    {
      content: stepSetupAppblocksMarkup
    }
  ];

  // End steps

  // No OS 2.0 support markup
  // Install button markup
  const installButtonMarkup = (
    <FormItem {...formItemLayout} className="gx-mb-0 gx-text-center">
      <Button
        data-testid="setup-theme-button"
        type="primary"
        onClick={handleSetup}
        loading={setupBtnLoading}
        className="gx-mb-0"
        style={{ display: enableSaveButton ? "inline-block" : "none" }}
      >
        {setupBtnText}
      </Button>
    </FormItem>
  );

  const noOs2FormMarkup = (
    <>
      {selectLayoutMarkup}
      {installButtonMarkup}
    </>
  );

  // Check selected theme is supported or not
  const {
    data: checkIsSupportedResult,
    isLoading: isSupportedLoading
  } = useCheckIsSupportedTheme(selectedTheme);
  useEffect(() => {
    if (
      checkIsSupportedResult?.isSupported &&
      !!checkIsSupportedResult?.supportedTheme?.layoutType
    ) {
      setFieldsValue({
        layoutType: checkIsSupportedResult?.supportedTheme?.layoutType,
        "supportedThemeInfo.isSupported": checkIsSupportedResult?.isSupported,
        "supportedThemeInfo.themeInfo.name":
          checkIsSupportedResult?.themeInfo?.name,
        "supportedThemeInfo.themeInfo.author":
          checkIsSupportedResult?.themeInfo?.author,
        "supportedThemeInfo.themeInfo.version":
          checkIsSupportedResult?.themeInfo?.version
      });
    } else {
      resetFields([
        "layoutType",
        "supportedThemeInfo.isSupported",
        "supportedThemeInfo.themeInfo.name",
        "supportedThemeInfo.themeInfo.author",
        "supportedThemeInfo.themeInfo.version"
      ]);
    }
  }, [checkIsSupportedResult]);

  return (
    <Form onSubmit={handleSetup}>
      { checkOs2Result.isOs2 ? (
        <p>See quick guide <CustomLink href={docs.gettingsStarted.installLayout.installToOs2Theme} children="How to install to a OS 2.0 theme"/></p>
      ) : null}
      {/* Select a theme */}
      <FormItem
        {...formItemLayout}
        label={
          <span data-testid="theme-form-theme-label">
            Select a theme to install search and filter layout
          </span>
        }
      >
        {getFieldDecorator("theme", {
          rules: [
            {
              required: true,
              message: "Please select your theme."
            }
          ]
        })(
          <Select
            data-testid="select-theme-input"
            showSearch
            style={{ width: "100%" }}
            placeholder="Select a theme"
            optionFilterProp="children"
            onFocus={handleFocusSelectTheme}
            notFoundContent={itemLoading ? <Spin size="small" /> : null}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {themeOptions}
          </Select>
        )}
      </FormItem>
      {/* End select a theme */}
      {/* Supported theme */}
      <FormItem style={{ display: "none" }}>
        {getFieldDecorator("supportedThemeInfo.isSupported", {
          valuePropName: "checked"
        })(<Checkbox />)}
      </FormItem>
      <FormItem style={{ display: "none" }}>
        {getFieldDecorator("supportedThemeInfo.themeInfo.name")(<Input />)}
      </FormItem>
      <FormItem style={{ display: "none" }}>
        {getFieldDecorator("supportedThemeInfo.themeInfo.author")(<Input />)}
      </FormItem>
      <FormItem style={{ display: "none" }}>
        {getFieldDecorator("supportedThemeInfo.themeInfo.version")(<Input />)}
      </FormItem>
      {/* {selectedTheme ? (
        <div className="gx-mb-4">
          {isSupportedLoading ? (
            <div className="gx-d-flex">
              <SkeletonDisplayText size="small" loading={isSupportedLoading} />
              <SkeletonDisplayText size="small" loading={isSupportedLoading} />
            </div>
          ) : (
            <div>
              {checkIsSupportedResult.isSupported ? (
                <div>
                  <p>
                    Your theme {checkIsSupportedResult?.themeInfo?.name}{" "}
                    (Version: {checkIsSupportedResult?.themeInfo?.version}) by{" "}
                    {checkIsSupportedResult?.themeInfo?.author} is{" "}
                    <b className="gx-text-primary">supported</b>. We will apply
                    all configuration that is suitable for your theme
                    automatically.
                  </p>
                  <p>
                    You can go to <b>Customization</b> page to customize more
                    for your filter and search widget after installing
                    successfully.
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Your theme {checkIsSupportedResult?.themeInfo?.name}{" "}
                    (Version: {checkIsSupportedResult?.themeInfo?.version}) by{" "}
                    {checkIsSupportedResult?.themeInfo?.author} is{" "}
                    <b className="gx-text-danger">NOT supported</b>. We will
                    apply the <b>default configuration</b> that for your theme.
                  </p>
                  <p>
                    You can go to <b>Customization</b> page to customize more
                    for your filter and search widget after installing
                    successfully.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null} */}
      {/* End Supported theme */}
      {/* Is OS 2.0 */}
      <FormItem
        {...formItemLayout}
        label={
          <span data-testid="theme-form-theme-label">
            Select a theme to install search and filter layout
          </span>
        }
        style={{ display: "none" }}
      >
        {getFieldDecorator("isOs2")(<Input />)}
      </FormItem>
      {/* End is OS 2.0 */}
      <CustomSpin
        spinning={isCheckOs2Loading}
        size={"large"}
        tip="Checking support OS 2.0 ..."
      >
        {checkOs2Result.isOs2 ? (
          <>
            <h3>Your theme supported Online store 2.0</h3>
            {supportStatus === SUPPORT_STATUS_FULL ||
            supportStatus === SUPPORT_STATUS_COLLECTION ? (
              <p>
                <StepIcon isSuccess={true} />
                Supported app block on Collection page
              </p>
            ) : null}
            {supportStatus === SUPPORT_STATUS_FULL ||
            supportStatus === SUPPORT_STATUS_SEARCH ? (
              <p>
                <StepIcon isSuccess={true} />
                Supported app block on Search page
              </p>
            ) : null}

            <Divider />
            <h3 className="gx-mb-4">
              Complete 3 steps to install filter and search layout to your theme
            </h3>

            {/* Steps */}
            <Steps current={currentStep}>
              <Step title="Step 1" description="Select a layout." />
              <Step
                title="Step 2"
                description="Enable app embed blocks."
                disabled={isCreateSetupThemeLoading}
              />
              <Step
                title="Step 3"
                description="Setup app blocks."
                disabled={isCreateSetupThemeLoading}
              />
            </Steps>
            <div className="gx-px-5 gx-py-3">
              {steps[currentStep].content}
              <div className="gx-mt-4">
                {currentStep < steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={handleNextStep}
                    loading={isCreateSetupThemeLoading}
                    disabled={disableNextStep}
                  >
                    Next
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={handleDoneInstall}
                    disabled={disableNextStep}
                    loading={isDoneInstallLoading}
                  >
                    Done
                  </Button>
                )}
                {currentStep > 0 && (
                  <Button
                    onClick={handlePrevStep}
                    disabled={isCreateSetupThemeLoading}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  onClick={handleCancel}
                  disabled={isCreateSetupThemeLoading}
                  loading={isCancelLoading}
                  style={{ display: disableCancel ? "none" : undefined }}
                >
                  Cancel
                </Button>
              </div>
            </div>

            {/* End steps */}
          </>
        ) : (
          noOs2FormMarkup
        )}
      </CustomSpin>
    </Form>
  );
};

export default SetupThemeForm;

SetupThemeForm.propTypes = {
  form: PropTypes.any,
  formItemLayout: PropTypes.object,
  setupBtnText: PropTypes.string
};

SetupThemeForm.defaultProps = {
  setupBtnText: "Install layout"
};
