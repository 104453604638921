import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const createOne = (item) => {
  const url = `${ENDPOINT}/auth/redirects`;
  return axios({
    url,
    data: item,
    method: "post",
    withCredentials: true,
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/redirects?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateOne = (item) => {
  const url = `${ENDPOINT}/auth/redirects/${item.id}`;
  const {
    searchTerms, targetUrl, enable
  } = item;
  return axios({
    url,
    method: "put",
    data: {
      searchTerms,
      targetUrl,
      enable,
    },
    withCredentials: true,
  })
};

const deleteOne = (itemId) => {
  const url = `${ENDPOINT}/auth/redirects/${itemId}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
  })
};

const deleteMany = (itemIds) => {
  const url = `${ENDPOINT}/auth/redirects/bulk_delete`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: itemIds,
    },
    withCredentials: true,
  })
};

const redirectAPI = {
  createOne,
  getAll,
  updateOne,
  deleteOne,
  deleteMany,
};

export default redirectAPI;
