import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import settingsAPI from "../../services/settings";
import {getAllSettings, getAllSettingsSuccess} from "../../appRedux/slices/Settings";
import {fetchItemIdStart, fetchItemIdSuccess, fetchItemIdError} from "../../appRedux/slices/Common";
import {GET_ALL_SETTINGS_LOADING_ID} from "../../constants/loadingIds/Settings";

function* getAllSettingsRequest() {
  try {
    yield put(fetchItemIdStart(GET_ALL_SETTINGS_LOADING_ID));
    const {data} = yield call(settingsAPI.getAllSettings);
    yield put(getAllSettingsSuccess(data));
    yield put(fetchItemIdSuccess(GET_ALL_SETTINGS_LOADING_ID));
  } catch (error) {
    yield put(fetchItemIdError(GET_ALL_SETTINGS_LOADING_ID));
  }
}

function* getAllSettingsSaga() {
  yield takeEvery(getAllSettings, getAllSettingsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getAllSettingsSaga),
  ]);
}
