import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchItemIdError, fetchItemIdStart, fetchItemIdSuccess, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createARedirect,
  createARedirectSuccess,
  getAllRedirects,
  getAllRedirectsSuccess,
  deleteARedirect,
  deleteManyRedirects,
  deleteManyRedirectsSuccess,
  updateARedirect,
  updateARedirectSuccess, updateStatusARedirectSuccess, updateStatusARedirect,
} from "../slices/Redirect";
import redirectAPI from "../../services/redirect";

// Create a redirect
function* createARedirectRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(redirectAPI.createOne, item);
    yield put(createARedirectSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Redirect created"));
    yield put(getAllRedirects());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createARedirectSaga() {
  yield takeEvery(createARedirect, createARedirectRequest);
}

// Get all redirects
function* getAllRedirectsRequest({payload}) {
  const searchTerm = payload.search;
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(redirectAPI.getAll, payload);
    yield put(getAllRedirectsSuccess(data, searchTerm));
    yield put(fetchPartialSuccess());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllRedirectsSaga() {
  yield takeEvery(getAllRedirects, getAllRedirectsRequest)
}

// Update a redirect
function* updateARedirectRequest({payload}) {
  const {redirect, metadata} = payload;
  const {offset, searchTerm} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(redirectAPI.updateOne, redirect);
    yield put(updateARedirectSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Redirect updated"));
    yield put(getAllRedirects({offset, search: searchTerm}));
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateARedirectSaga() {
  yield takeEvery(updateARedirect, updateARedirectRequest);
}

// Update status of a redirect
function* updateStatusARedirectRequest({payload}) {
  const item = payload;
  const {id, enable} = item;
  try {
    yield put(fetchItemIdStart(id));
    yield call(redirectAPI.updateOne, item);
    yield put(updateStatusARedirectSuccess(item));
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Redirect ${enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id));
  }
}

function* updateStatusARedirectSaga() {
  yield takeEvery(updateStatusARedirect, updateStatusARedirectRequest);
}

// Delete a redirect
function* deleteARedirectRequest({payload}) {
  try {
    const redirect = payload;
    yield put(fetchPartialStart());
    yield call(redirectAPI.deleteOne, redirect.id);
    yield put(fetchPartialSuccess());
    yield put(showMessage("Redirect deleted"));
    yield put(getAllRedirects());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteARedirectSaga() {
  yield takeEvery(deleteARedirect, deleteARedirectRequest);
}

// Delete many redirects
function* deleteManyRedirectsRequest({payload}) {
  try {
    const ids = payload.map(p => p.id);
    yield put(fetchPartialStart());
    yield call(redirectAPI.deleteMany, ids);
    yield put(deleteManyRedirectsSuccess());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Selected redirects deleted"));
    yield put(getAllRedirects());

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteManyRedirectsSaga() {
  yield takeEvery(deleteManyRedirects, deleteManyRedirectsRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createARedirectSaga),
    fork(getAllRedirectsSaga),
    fork(deleteARedirectSaga),
    fork(updateARedirectSaga),
    fork(updateStatusARedirectSaga),
    fork(deleteManyRedirectsSaga),
  ]);
}
