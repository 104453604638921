import moment from 'moment';

export const tmpToDateStr = (tmp) => {
  return moment.unix(tmp).format("MMMM D YYYY, h:mm A")
};

export const makeDateRangeByDays = days => {
  return [
    moment()
      .subtract(days - 1, "days")
      .startOf("day"),
    moment().endOf("day")
  ]
}

export const makeTimeStampRangeByDays = days => {
  return [
    moment()
      .subtract(days - 1, "days")
      .startOf("day").unix(),
    moment().endOf("day").unix()
  ]
}
