import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {updateSelectedItems, updateSelectedItem, updateItemEnableStatus} from "./utils";
import {FILTER_GROUP_TYPE_DEFAULT_COLLECTION, FILTER_GROUP_TYPE_SEARCH} from "../../routes/FilterGroups/Main/constants";


const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  searchTerm: '',
  offset: 1,
  didFirstGetAllRequest: false,
  firstGetAllLoading: false,
};

const INIT_COMMON = {
  selectedItemCount: 0,
  error: '',
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {},
};

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "filterGroup",
  initialState: INIT_STATE,
  reducers: {
    // Create a item
    createAFilterGroup() {},

    createAFilterGroupSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
      }
    },

    showCreateAFilterGroupModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        showCreateAItemModal: true,
      }
    },

    hideCreateAFilterGroupModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all items
    getAllFilterGroups: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
          params.search = params.search || '';
        }
        return {
          payload: params
        }
      }
    },

    getAllFilterGroupsSuccess: {
      reducer(state, action) {
        const {data, searchTerm} = action.payload;
        const {total, results} = data;

        // Set selected=false by default for all items
        const items = updateSelectedItems(results, false);

        const stateResult = {
          ...state,
          items,
          totalCurrentItems: total,
          selectedItemCount: 0,
          firstGetAllLoading: false,
        };

        if (!searchTerm) {
          stateResult.totalItems = total;
        }

        return stateResult;
      },
      prepare(data, searchTerm) {
        return {payload: {data, searchTerm}}
      }
    },

    setFirstGetAllFilterGroupsLoading(state, action) {
      state.firstGetAllLoading = action.payload;
    },

    setFilterGroupSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setDidFirstGetAllFilterGroupRequest(state, action) {
      state.didFirstGetAllRequest = action.payload;
    },

    // Update a item
    updateAFilterGroup: {
      reducer(state) {
        return state;
      },
      prepare(filterGroup, metadata) {
        return {payload: {filterGroup, metadata}};
      }
    },

    updateAFilterGroupSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    updateStatusAFilterGroup() {},

    updateStatusAFilterGroupSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    showEditAFilterGroupModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditAFilterGroupModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Delete a item
    deleteAFilterGroup() {},

    // Delete many
    deleteManyFilterGroups() {},

    deleteManyFilterGroupsSuccess(state) {
      state.selectedItemCount = 0;
    },

    // Common
    updateSelectedFilterGroup(state, action) {
      state.selectedItemCount = updateSelectedItem(state.items, action.payload);
    },

    updateAllFilterGroupsToSelected(state) {
      const defaultItems = [];
      const customItems = [];
      state.items.forEach(item => {
        if (item.type === FILTER_GROUP_TYPE_DEFAULT_COLLECTION || item.type === FILTER_GROUP_TYPE_SEARCH) {
          defaultItems.push(item);
        } else {
          customItems.push(item);
        }
      })
      state.items = [...defaultItems, ...updateSelectedItems(customItems, true)];
      state.selectedItemCount = customItems.length;
    },

    updateAllFilterGroupsToUnSelected(state) {
      updateSelectedItems(state.items, false);
      state.selectedItemCount = 0;
    },
  }
});

export const {
  createAFilterGroup,
  createAFilterGroupSuccess,
  showCreateAFilterGroupModal,
  hideCreateAFilterGroupModal,
  getAllFilterGroups,
  getAllFilterGroupsSuccess,
  setFirstGetAllFilterGroupsLoading,
  setFilterGroupSearchTerm,
  setDidFirstGetAllFilterGroupRequest,
  updateAFilterGroup,
  updateAFilterGroupSuccess,
  updateStatusAFilterGroup,
  updateStatusAFilterGroupSuccess,
  showEditAFilterGroupModal,
  hideEditAFilterGroupModal,
  deleteAFilterGroup,
  deleteManyFilterGroups,
  deleteManyFilterGroupsSuccess,
  updateSelectedFilterGroup,
  updateAllFilterGroupsToSelected,
  updateAllFilterGroupsToUnSelected,
} = slice.actions;

export default slice.reducer;
