import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from "../appRedux/slices/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "../components/CircularProgress/index";
import fsLogo from "../assets/images/fs-logo.svg";

const FormItem = Form.Item;

const SignIn = ({form}) => {
  const { getFieldDecorator, validateFields } = form;

  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage} = useSelector(({auth}) => auth);

  // show signin message
  useEffect(() => {
    if (showMessage) {
      message.error(alertMessage.toString());
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  }, [alertMessage, dispatch, showMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        dispatch(showAuthLoader());
        dispatch(userSignIn(values));
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            {/*<div className="gx-app-logo-content-bg">*/}
            {/*  <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>*/}
            {/*</div>*/}
            <div className="gx-app-logo-wid">
              <h1 className="gx-text-primary"><IntlMessages id="app.userAuth.signIn"/></h1>
              <p className="gx-text-primary"><IntlMessages id="app.userAuth.bySigning"/></p>
              {/*<p><IntlMessages id="app.userAuth.getAccount"/></p>*/}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={fsLogo}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form onSubmit={handleSubmit} className="gx-signin-form gx-form-row0">

              <FormItem>
                {getFieldDecorator('email', {
                  rules: [{
                    required: true, type: 'email', message: 'The input is not valid E-mail!',
                  }],
                })(
                  <Input placeholder="Email"/>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{required: true, message: 'Please input your Password!'}],
                })(
                  <Input type="password" placeholder="Password"/>
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                )}
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
                {/*<span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages*/}
                {/*id="app.userAuth.signUp"/></Link>*/}
              </FormItem>
              {/*<div className="gx-flex-row gx-justify-content-between">*/}
              {/*  <span>or connect with</span>*/}
              {/*  <ul className="gx-social-link">*/}
              {/*    <li>*/}
              {/*      <Icon type="google" onClick={() => {*/}
              {/*        dispatch(showAuthLoader());*/}
              {/*        dispatch(userGoogleSignIn());*/}
              {/*      }}/>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Icon type="facebook" onClick={() => {*/}
              {/*        dispatch(showAuthLoader());*/}
              {/*        dispatch(userFacebookSignIn());*/}
              {/*      }}/>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Icon type="github" onClick={() => {*/}
              {/*        dispatch(showAuthLoader());*/}
              {/*        dispatch(userGithubSignIn());*/}
              {/*      }}/>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Icon type="twitter" onClick={() => {*/}
              {/*        dispatch(showAuthLoader());*/}
              {/*        dispatch(userTwitterSignIn());*/}
              {/*      }}/>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
              {/*<span*/}
              {/*  className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span>*/}
            </Form>
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}
        </div>
      </div>
    </div>
  );
};

const WrappedSignInForm = Form.create()(SignIn);

export default WrappedSignInForm;
