import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess,
  fetchItemIdStart,
  fetchItemIdSuccess,
  fetchItemIdError,
  showMessage,
} from "../slices/Common";
import {
  getAllDefaultSearchFields,
  getAllDefaultSearchFieldsSuccess, showDfSfSyncBanner,
  updateADefaultSearchField,
  updateADefaultSearchFieldSuccess,
  updateStatusADefaultSearchField,
  updateStatusADefaultSearchFieldSuccess,
} from "../slices/DefaultSearchField";
import defaultSearchFieldAPI from "../../services/defaultSearchField";
import {DEFAULT_SEARCH_FIELD_LOADING_ID} from "../../constants/loadingIds";

// Get all items
function* getAllDefaultSearchFieldsRequest({payload}) {
  try {
    yield put(fetchItemIdStart(DEFAULT_SEARCH_FIELD_LOADING_ID));
    const {data} = yield call(defaultSearchFieldAPI.getAll, payload);
    yield put(getAllDefaultSearchFieldsSuccess(data));
    yield put(fetchItemIdSuccess(DEFAULT_SEARCH_FIELD_LOADING_ID));
  } catch (error) {
    yield put(fetchItemIdError(DEFAULT_SEARCH_FIELD_LOADING_ID));
  }
}

function* getAllDefaultSearchFieldsSaga() {
  yield takeEvery(getAllDefaultSearchFields, getAllDefaultSearchFieldsRequest)
}

// Update a item
function* updateADefaultSearchFieldRequest({payload}) {
  const {searchField} = payload;
  try {
    yield put(fetchPartialStart());
    yield call(defaultSearchFieldAPI.updateOne, searchField);
    yield put(updateADefaultSearchFieldSuccess());
    yield put(showDfSfSyncBanner());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Default search field updated"));
    yield put(getAllDefaultSearchFields());
  } catch (error) {
    yield put(fetchPartialError())
  }
}

function* updateADefaultSearchFieldSaga() {
  yield takeEvery(updateADefaultSearchField, updateADefaultSearchFieldRequest);
}

// Update status a metafield search field
function* updateStatusADefaultSearchFieldRequest({payload}) {
  const item = payload;
  const id = item.id;
  try {
    yield put(fetchItemIdStart(id));
    yield call(defaultSearchFieldAPI.updateOne, item);
    yield put(updateStatusADefaultSearchFieldSuccess(item));
    yield put(showDfSfSyncBanner());
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Default search field ${item.enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id))
  }
}

function* updateStatusAMetafieldSearchFieldSaga() {
  yield takeEvery(updateStatusADefaultSearchField, updateStatusADefaultSearchFieldRequest);
}

export default function* rootSaga() {
  yield all([
    fork(getAllDefaultSearchFieldsSaga),
    fork(updateADefaultSearchFieldSaga),
    fork(updateStatusAMetafieldSearchFieldSaga),
  ]);
}
