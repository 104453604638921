import {createSlice} from "@reduxjs/toolkit";

const INIT_SETUP_THEME = {
  showSetupThemeModal: false,
};

const INIT_REVERT_THEME = {
  showRevertThemeModal: false,
};

const INIT_GET_ALL_SETUP_THEMES = {
  setupThemes: [],
  totalSetupThemes: 0,
  didFirstGetAllSetupThemesRequest: false,
};

const INIT_COMMON = {
};

const INIT_STATE = {
  ...INIT_SETUP_THEME,
  ...INIT_REVERT_THEME,
  ...INIT_GET_ALL_SETUP_THEMES,
  ...INIT_COMMON,
};

const slice = createSlice({
  name: "themeSetup",
  initialState: INIT_STATE,
  reducers: {
    // Setup
    showSetupThemeModal(state) {
      return {
        ...state,
        ...INIT_SETUP_THEME,
        showSetupThemeModal: true,
      }
    },

    hideSetupThemeModal(state) {
      state.showSetupThemeModal = false;
    },

    setup() {},

    setupSuccess(state) {
      return {
        ...state,
        ...INIT_SETUP_THEME,
      }
    },

    // Revert
    showRevertThemeModal(state) {
      return {
        ...state,
        ...INIT_REVERT_THEME,
        showRevertThemeModal: true,
      }
    },

    hideRevertThemeModal(state) {
      state.showRevertThemeModal = false;
    },

    revert() {},

    revertSuccess(state) {
      return {
        ...state,
        ...INIT_REVERT_THEME,
      }
    },

    // Get all setup themes
    getAllSetupThemes() {},

    getAllSetupThemesSuccess(state, action) {
      const {total, results} = action.payload;

      return {
        ...state,
        setupThemes: results,
        totalSetupThemes: total,
      }
    },

    setDidFirstGetAllSetupThemesRequest(state, action) {
      state.didFirstGetAllSetupThemesRequest = action.payload;
    },
  }
});

export const {
  // Setup
  showSetupThemeModal,
  hideSetupThemeModal,
  setup,
  setupSuccess,

  // Revert
  showRevertThemeModal,
  hideRevertThemeModal,
  revert,
  revertSuccess,

  // Get all setup themes
  getAllSetupThemes,
  getAllSetupThemesSuccess,

  setDidFirstGetAllSetupThemesRequest,
} = slice.actions;

export default slice.reducer;
