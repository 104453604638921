import {useDispatch} from "react-redux";
import {fetchError} from "../../appRedux/slices/Common";
import {useEffect} from "react";

const useHandleErrorMsg = query => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (query.isError) {
      dispatch(fetchError());
    }
  }, [dispatch, query.isError]);

}

export default useHandleErrorMsg;
