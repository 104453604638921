import axios from "axios";

import config from "../config";

const ENDPOINT = config.apiEndpoint;

const getAllShopifyThemes = () => {
  const url = `${ENDPOINT}/auth/themes/shopify/themes`;
  return axios({
    url,
    method: 'get',
    withCredentials: true,
  });
};

const shopifyThemeAPI = {
  getAllShopifyThemes,
};

export default shopifyThemeAPI;
