import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchError, fetchPartialError, fetchPartialStart, fetchPartialSuccess,
  fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  setup,
  setupSuccess,
  revert,
  revertSuccess,
  getAllSetupThemes,
  getAllSetupThemesSuccess,
} from "../slices/Theme";
import themeAPI from "../../services/theme";

// Setup theme
function* setupRequest({payload}) {
  try {
    yield put(fetchStart());
    yield call(themeAPI.setup, payload);
    yield put(setupSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Layout installed"));
    yield put(getAllSetupThemes());
  } catch (error) {
    yield put(fetchError());
  }
}

function* setupSaga() {
  yield takeEvery(setup, setupRequest);
}

// Revert theme
function* revertRequest({payload}) {
  try {
    yield put(fetchStart());
    yield call(themeAPI.revert, payload);
    yield put(revertSuccess());
    yield put(fetchSuccess());
    yield put(showMessage("Layout uninstalled"))
    yield put(getAllSetupThemes());
  } catch (error) {
    yield put(fetchError());
  }
}

function* revertSaga() {
  yield takeEvery(revert, revertRequest);
}

// Get all setup themes
function* getAllSetupThemesRequest() {
  try {
    yield put(fetchPartialStart());
    const {data} = yield call(themeAPI.getAllSetupThemes);
    yield put(getAllSetupThemesSuccess(data));
    yield put(fetchPartialSuccess());

  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* getAllSetupThemesSaga() {
  yield takeEvery(getAllSetupThemes, getAllSetupThemesRequest)
}

export default function* rootSaga() {
  yield all([
    fork(setupSaga),
    fork(revertSaga),
    fork(getAllSetupThemesSaga),
  ]);
}
