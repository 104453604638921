import { useDispatch, useSelector } from "react-redux";
import { startSyncShopify } from "../appRedux/slices/Auth";
import {
  SYNC_STATUS_COMPLETED,
  SYNC_STATUS_FAILED,
  SYNC_STATUS_RUNNING
} from "../constants/Sync";

const useSync = () => {
  const dispatch = useDispatch();
  const startSync = () => {
    dispatch(startSyncShopify());
  };

  // Status
  const syncStatus = useSelector(({ auth }) => auth.syncStatus);
  const syncStatusOfUser = useSelector(
    ({ auth }) => auth?.authUser?.store?.syncStatus
  );

  const isRunning = syncStatus === SYNC_STATUS_RUNNING;
  const isFailed = syncStatus === SYNC_STATUS_FAILED;
  const isCompleted = syncStatus === SYNC_STATUS_COMPLETED;
  const isFailedOrStatusOfUserFailed =
    isFailed || syncStatusOfUser === SYNC_STATUS_FAILED;

  // End status

  const lastSyncAt = useSelector(
    ({ auth }) => auth.authUser?.store?.lastSyncAt
  );

  return {
    startSync,
    isRunning,
    isFailed,
    isFailedOrStatusOfUserFailed,
    isCompleted,
    lastSyncAt
  };
};

export default useSync;
