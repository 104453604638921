import {call, put, takeEvery, fork, all} from "redux-saga/effects";

import {
  fetchItemIdStart,
  fetchItemIdSuccess,
  fetchItemIdError,
  fetchError, fetchPartialError,
  fetchPartialStart,
  fetchPartialSuccess, fetchStart,
  fetchSuccess,
  showMessage,
} from "../slices/Common";
import {
  createAVariantMetafieldSearchField,
  createAVariantMetafieldSearchFieldSuccess,
  getAllVariantMetafieldSearchFields,
  getAllVariantMetafieldSearchFieldsSuccess,
  deleteAVariantMetafieldSearchField,
  updateAVariantMetafieldSearchField,
  updateAVariantMetafieldSearchFieldSuccess,
  updateStatusAVariantMetafieldSearchField,
  updateStatusAVariantMetafieldSearchFieldSuccess, showVMfSfSyncBanner,
} from "../slices/VariantMetafieldSearchField";
import variantMetafieldSearchFieldAPI from "../../services/variantMetafieldSearchField";
import {VARIANT_METAFIELD_SEARCH_FIELD_LOADING_ID} from "../../constants/loadingIds";

function* createAVariantMetafieldSearchFieldRequest({payload}) {
  const item = payload;
  try {
    yield put(fetchStart());
    yield call(variantMetafieldSearchFieldAPI.createOne, item);
    yield put(createAVariantMetafieldSearchFieldSuccess());
    yield put(showVMfSfSyncBanner());
    yield put(fetchSuccess());
    yield put(showMessage("Search field by variant metafield created"));
    yield put(getAllVariantMetafieldSearchFields());
  } catch (error) {
    yield put(fetchError());
  }
}

function* createAVariantMetafieldSearchFieldSaga() {
  yield takeEvery(createAVariantMetafieldSearchField, createAVariantMetafieldSearchFieldRequest);
}

function* getAllVariantMetafieldSearchFieldsRequest({payload}) {
  try {
    yield put(fetchItemIdStart(VARIANT_METAFIELD_SEARCH_FIELD_LOADING_ID));
    const {data} = yield call(variantMetafieldSearchFieldAPI.getAll, payload);
    yield put(getAllVariantMetafieldSearchFieldsSuccess(data));
    yield put(fetchItemIdSuccess(VARIANT_METAFIELD_SEARCH_FIELD_LOADING_ID));
  } catch (error) {
    yield put(fetchItemIdError(VARIANT_METAFIELD_SEARCH_FIELD_LOADING_ID));
  }
}

function* getAllVariantMetafieldSearchFieldsSaga() {
  yield takeEvery(getAllVariantMetafieldSearchFields, getAllVariantMetafieldSearchFieldsRequest)
}

function* updateAVariantMetafieldSearchFieldRequest({payload}) {
  const {searchField, metadata} = payload;
  const {offset} = metadata;
  try {
    yield put(fetchPartialStart());
    yield call(variantMetafieldSearchFieldAPI.updateOne, searchField);
    yield put(updateAVariantMetafieldSearchFieldSuccess());
    yield put(showVMfSfSyncBanner());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Search field my variant metafield updated"));
    yield put(getAllVariantMetafieldSearchFields({offset}));
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* updateAVariantMetafieldSearchFieldSaga() {
  yield takeEvery(updateAVariantMetafieldSearchField, updateAVariantMetafieldSearchFieldRequest);
}

function* updateStatusAVariantMetafieldSearchFieldRequest({payload}) {
  const item = payload;
  const id = item.id;
  try {
    yield put(fetchItemIdStart(id));
    yield call(variantMetafieldSearchFieldAPI.updateOne, item);
    yield put(updateStatusAVariantMetafieldSearchFieldSuccess(item));
    yield put(showVMfSfSyncBanner());
    yield put(fetchItemIdSuccess(id));
    yield put(showMessage(`Search field my variant metafield ${item.enable ? "enabled" : "disabled"}`));
  } catch (error) {
    yield put(fetchItemIdError(id))
  }
}

function* updateStatusAVariantMetafieldSearchFieldSaga() {
  yield takeEvery(updateStatusAVariantMetafieldSearchField, updateStatusAVariantMetafieldSearchFieldRequest);
}

function* deleteAVariantMetafieldSearchFieldRequest({payload}) {
  try {
    const searchField = payload;
    yield put(fetchPartialStart());
    yield call(variantMetafieldSearchFieldAPI.deleteOne, searchField.id);
    yield put(showVMfSfSyncBanner());
    yield put(fetchPartialSuccess());
    yield put(showMessage("Search field by variant metafield deleted"));
    yield put(getAllVariantMetafieldSearchFields());
  } catch (error) {
    yield put(fetchPartialError());
  }
}

function* deleteAVariantMetafieldSearchFieldSaga() {
  yield takeEvery(deleteAVariantMetafieldSearchField, deleteAVariantMetafieldSearchFieldRequest);
}

export default function* rootSaga() {
  yield all([
    fork(createAVariantMetafieldSearchFieldSaga),
    fork(getAllVariantMetafieldSearchFieldsSaga),
    fork(deleteAVariantMetafieldSearchFieldSaga),
    fork(updateAVariantMetafieldSearchFieldSaga),
    fork(updateStatusAVariantMetafieldSearchFieldSaga),
  ]);
}
