import React, {useState} from "react";
import {Avatar, Divider, Popover} from "antd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CustomBadge from "../CustomBadge";
import useSubPlan from "../../hooks/useSubPlan";
import useGoToPage from "../../hooks/useGoToPage";

const UserInfo = ({ layoutType }) => {
  const authUser = useSelector(({ auth }) => auth.authUser);

  const {planName, trialDaysLeft, isDevPlan, isPremiumPlan} = useSubPlan();

  const isMobileLayout = layoutType === "mobile";

  const [visible, setVisible] = useState(false);
  const handleVisibleChange = visible => {
    setVisible(visible);
  };
  const hideMenu = () => {
    setVisible(false);
  }

  const {goToPage} = useGoToPage();

  function handleClickMyAccount() {
    goToPage("/settings/myAccount")
    hideMenu();
  }

  function handleClickSubPlan() {
    goToPage("/settings/subscriptionPlan")
    hideMenu();
  }

  function handleClickSettings() {
    goToPage("/settings")
    hideMenu();
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={handleClickMyAccount} data-testid="menu-item-my-account">My Account</li>
      <Divider />
      <li onClick={handleClickSubPlan} data-testid="menu-item-sub-plan">Subscription plan</li>
      <li onClick={handleClickSettings} data-testid="menu-item-sub-plan">Settings</li>
    </ul>
  )

  return (
    <Popover
      overlayClassName={isMobileLayout ? "gx-popover-horizantal" : undefined}
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <CustomBadge count={planName} status={isPremiumPlan ? "success" : "warning"} visible={!isMobileLayout} data-testid="avatar-badge-plan">
        <Avatar
          className={
            isMobileLayout
              ? "gx-avatar gx-pointer"
              : "gx-size-40 gx-pointer gx-mr-2"
          }
          alt=""
          style={{ userSelect: "none" }}
        >
          {authUser && authUser.name
            ? authUser.name.charAt(0).toUpperCase()
            : "G"}
        </Avatar>
      </CustomBadge>
      {!isMobileLayout ? (
        <span className="gx-avatar-name" style={{ userSelect: "none" }} data-testid="avatar-name">
          {authUser.name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      ) : null}
    </Popover>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  layoutType: PropTypes.oneOf(["desktop", "mobile"])
};

UserInfo.defaultProps = {
  layoutType: "desktop"
};
