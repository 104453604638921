export const PATH_CUSTOMIZATION = "/customization";

export const CUSTOMIZE_GENERAL_PRODUCT_LABEL = "GENERAL_PRODUCT_LABEL";
export const CUSTOMIZE_GENERAL_PRICE = "GENERAL_PRICE";

export const CUSTOMIZE_INSTANT_SEARCH = "INSTANT_SEARCH";

export const CUSTOMIZE_SR_GENERAL = "SR_GENERAL";
export const CUSTOMIZE_SR_LAYOUT = "SR_LAYOUT";
export const CUSTOMIZE_SR_PRODUCT_LABEL = "SR_PRODUCT_LABEL";
export const CUSTOMIZE_SR_PRODUCT_IMAGE = "SR_PRODUCT_IMAGE";
export const CUSOTMIZE_COLLECTION_PAGE_HEADING = "COLLECTION_PAGE_HEADING";
export const CUSTOMIZE_COLLECTION_PAGE_HEADER = "COLLECTION_PAGE_HEADER";
export const CUSOTMIZE_SEARCH_PAGE_HEADING = "SEARCH_PAGE_HEADING";
export const CUSTOMIZE_SEARCH_PAGE_HEADER = "SEARCH_PAGE_HEADER";
export const CUSTOMIZE_TOOLBARS = "TOOLBARS";
export const CUSTOMIZE_PRODUCT_ITEM = "PRODUCT_ITEM";
export const CUSTOMIZE_GRID_VIEW_PRODUCT_ITEM = "GRID_VIEW_PRODUCT_ITEM";
export const CUSTOMIZE_LIST_VIEW_PRODUCT_ITEM = "LIST_VIEW_PRODUCT_ITEM";
export const CUSTOMIZE_QUICK_VIEW = "QUICK_VIEW";
export const CUSTOMIZE_PAGINATION = "PAGINATION";
export const CUSTOMIZE_FILTERS = "FILTERS";
export const CUSTOMIZE_SR_EMPTY_PAGE = "SR_EMPTY_PAGE";
