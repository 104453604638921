import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  ApiOutlined,
  HomeOutlined,
  MergeCellsOutlined,
  SyncOutlined
} from "@ant-design/icons";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import BgColorsOutlined from "@ant-design/icons/lib/icons/BgColorsOutlined";
import useLiveChat from "../../hooks/useLiveChat";
import { useGetSyncStatusOfAllTye } from "../../routes/hooks/useSync/useSync";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ themeSettings }) => themeSettings
  );

  const getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[0];
  const defaultOpenKeys = "filterGroups";

  const { handleLiveChat } = useLiveChat();

  const syncStatusH = useGetSyncStatusOfAllTye();

  return (
    <>
      <SidebarLogo />
      <div data-testid="sidebar-content" className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            // className="gx-d-flex gx-flex-column gx-h-100"
          >
            <MenuItemGroup className="gx-menu-group">
              <Menu.Item key="home">
                <Link to="/home" className="gx-d-flex gx-align-items-center">
                  <HomeOutlined style={{ fontSize: "20px" }} />
                  <span>
                    <IntlMessages id="sidebar.home" />
                  </span>
                </Link>
              </Menu.Item>
              {/* Sync */}
              <Menu.Item key="sync">
                <Link
                  to="/sync"
                  className="gx-d-flex gx-align-items-center"
                >
                  <SyncOutlined className={syncStatusH.isLoading? "gx-text-primary" : ""} spin={syncStatusH.isLoading} style={{"fontSize": "20px"}}/>
                  <span>
                    <IntlMessages id="sidebar.sync" />
                  </span>
                </Link>
              </Menu.Item>
              {/* End Sync */}
              {/* Search */}
              <SubMenu
                key="search"
                className={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span className="gx-d-flex gx-align-items-center">
                    <i className="icon icon-search-new" />
                    <span>
                      <IntlMessages id="sidebar.search" />
                    </span>
                  </span>
                }
              >
                <Menu.Item key="search/pinning">
                  <Link to="/search/pinning">
                    {/*<i className="icon icon-affix"/>*/}
                    <span>
                      <IntlMessages id="sidebar.search.pinning" />
                    </span>
                  </Link>
                </Menu.Item>
                {/*<Menu.Item key="search/merchandising">*/}
                {/*  <Link to="/search/merchandising">*/}
                {/*    /!*<i className="icon icon-extra-components"/>*!/*/}
                {/*    <span>*/}
                {/*      <IntlMessages id="sidebar.search.merchandising" />*/}
                {/*    </span>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="search/synonyms">
                  <Link to="/search/synonyms">
                    {/*<i className="icon icon-transfer"/>*/}
                    <span>
                      <IntlMessages id="sidebar.search.synonyms" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="search/redirect">
                  <Link to="/search/redirect">
                    {/*<i className="icon icon-sent"/>*/}
                    <IntlMessages id={"sidebar.search.redirect"} />
                  </Link>
                </Menu.Item>
              </SubMenu>
              {/* End search */}

              {/* Analytics */}
              <Menu.Item key="analytics">
                <Link
                  to="/analytics"
                  className="gx-d-flex gx-align-items-center"
                >
                  <i className="icon icon-chart-line" />
                  <span>
                    <IntlMessages id="sidebar.analytics" />
                  </span>
                </Link>
              </Menu.Item>
              {/* End analytics */}
              {/* Filters */}
              <MenuItemGroup
                key="filtersArea"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.filters" />}
              >
                {/* Filter group */}
                <Menu.Item key="filterGroups">
                  <Link
                    to="/filterGroups"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <i className="icon icon-filter" />
                    <span>
                      <IntlMessages id="sidebar.filters" />
                    </span>
                  </Link>
                </Menu.Item>
                {/* End filter group */}
                <Menu.Item key="filterOptionGroups">
                  <Link
                    to="/filterOptionGroups"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <MergeCellsOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <IntlMessages id="sidebar.filterOptionGroups" />
                    </span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              {/* End filters  */}
              {/* Themes */}
              <MenuItemGroup
                key="themes"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.themes" />}
              >
                <Menu.Item key="themeSetup">
                  <Link
                    to="/themeSetup"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <i className="icon icon-widgets" />
                    <span>
                      <IntlMessages id="sidebar.themeSetup" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="customization">
                  <Link
                    to="/customization"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <BgColorsOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <IntlMessages id="sidebar.customization" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="productColor">
                  <Link
                    to="/productColor"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <i className="icon icon-invert-color" />
                    <span>
                      <IntlMessages id="sidebar.productColor" />
                    </span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>
              {/* End themes */}
              {/* Integrations */}
              <MenuItemGroup
                key="integrations"
                className="gx-menu-group"
                title={<IntlMessages id="sidebar.integrations" />}
              >
                <Menu.Item key="integration">
                  <Link
                    to="/integration"
                    className="gx-d-flex gx-align-items-center"
                  >
                    <ApiOutlined style={{ fontSize: "20px" }} />
                    <span>
                      <IntlMessages id="sidebar.integration" />
                    </span>
                  </Link>
                </Menu.Item>
              </MenuItemGroup>

              {/* End integrations */}
            </MenuItemGroup>

            <MenuItemGroup
              key="helpCenter"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.helpCenter" />}
            >
              <Menu.Item key="pricing">
                <Link to="/pricing" className="gx-d-flex gx-align-items-center">
                  <i className="icon icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.pricing" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="faqs">
                <Link to="/faqs" className="gx-d-flex gx-align-items-center">
                  <i className="icon icon-chat" />
                  <span>
                    <IntlMessages id="sidebar.faqs" />
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="liveChat">
                <div
                  className="gx-d-flex gx-align-items-center"
                  onClick={handleLiveChat}
                >
                  <i className="icon icon-chat-bubble" />
                  <span>
                    <IntlMessages id="sidebar.liveChat" />
                  </span>
                </div>
              </Menu.Item>
            </MenuItemGroup>

            <Menu.Item key="settings" className="gx-mb-5">
              <Link to="/settings" className="gx-d-flex gx-align-items-center">
                <i className="icon icon-setting" />
                <span>
                  <IntlMessages id="sidebar.settings" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
