import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Form } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import GreenStepItem from "../components/Widgets/GreenStepItem";
import { useDispatch, useSelector } from "react-redux";
import {
  checkFirstIndexing,
  doFirstSetupWidget,
  doFirstSetupWidgetSuccess,
  firstSetupDone,
} from "../appRedux/slices/Auth";
import iiSearch from "../assets/images/common/fs-ii-search.svg";
import iiFast from "../assets/images/welcome/fs-ii-fast.svg";
import iiSuggestion from "../assets/images/welcome/fs-ii-suggestion.svg";
import iiMerchandising from "../assets/images/search/merchandising/fs-ii-merchandising.svg";
import SetupThemeForm from "../routes/ThemeSetup/SetupThemeForm";
import useHandleSetupTheme from "../routes/ThemeSetup/useHandleSetupTheme";
import useHandleCreateSetupTheme from "../routes/ThemeSetup/useHandleCreateSetupTheme";
import useMarkFirstInstallLayout from "../routes/hooks/theme/useMarkFirstInstallLayout"
import { useSync } from "../routes/hooks/useSync/useSync";
import PropagateLoader from "react-spinners/PropagateLoader";

const greenStepList = [
  {
    id: 1,
    title: "Super fast search speed",
    image: iiFast,
    desc: (
      <p>With our super fast search speed, the result will return instantly.</p>
    )
  },
  {
    id: 2,
    title: "Rich search types",
    image: iiSearch,
    desc: (
      <p>
        Search by product name, collections, pages, blogs, SKU, vendor,
        metafields ...
      </p>
    )
  },
  {
    id: 3,
    image: iiSuggestion,
    title: "Search products made easy",
    desc: (
      <>
        <p>
          Type in the first letter of a product name, our app will show the most
          popular search terms and relevant products, that suggest customers the
          products they wanted.
        </p>
        <p>
          If customers mistype "shift", our app will suggest "shirt" and show
          relevant products corresponding to "shirt".
        </p>
      </>
    )
  },
  {
    id: 4,
    image: iiMerchandising,
    title: "Promote your products",
    desc: (
      <>
        <p>
          Pin your selected products to the top search results or use
          merchandising rules to Push group of products to the top or bury to
          bottom of search results.
        </p>
        <p>
          Redirect your customers from a search term to another page to up-sell
          them with more shopping choices.
        </p>
      </>
    )
  }
];

const Slide = React.memo(props => {
  const { image, afterChange } = props;

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <div className="gx-slick-slider-lt-thumb">
          <img src={image} alt="" />
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <Slider
          className="gx-slick-slider gx-slick-slider-dot-top"
          {...settings}
          afterChange={afterChange}
        >
          {greenStepList.map((data, index) => (
            <GreenStepItem key={index} data={data} />
          ))}
        </Slider>
      </Col>
    </Row>
  );
});

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

const Welcome = ({ form }) => {
  const [image, setImage] = useState(greenStepList[0].image);
  const [showSetupWidget, setShowSetupWidget] = useState(false);

  const dispatch = useDispatch();
  const {
    firstIndexing,
    firstSetupWidget,
    firstSetup,
  } = useSelector(({ auth }) => auth.authUser.store);
  const { itemLoading, item2Loading } = useSelector(({ common }) => common);

  // Start first sync if here is the first indexing
  const syncH = useSync()
  useEffect(() => {
    if (firstIndexing === false) {
      syncH.sync();
    }
  }, [dispatch, firstIndexing]);

  // Fake indexing progress bar
  const [interv, setInterv] = useState();

  useEffect(() => {
    if (firstIndexing === true) {
      clearInterval(interv);
    }
  }, [firstIndexing, interv]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkFirstIndexing());
    }, 3000);
    setInterv(interval);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  // Handle when click done button
  const handleDone = useCallback(() => {}, []);

  const handleSlider = useCallback(index => {
    setImage(greenStepList[index].image);
  }, []);

  const onClickSetupWidget = () => {
    setShowSetupWidget(true);
  };

  const handleSetupWidget = useHandleSetupTheme(form, {
    requestFunc: reqData => {
      dispatch(doFirstSetupWidget({ ...reqData, firstSetup: true }));
    }
  });

  // When after install done layout for theme support OS 2.0
  const {markFirstInstallLayout, isLoading: isDoneInstallLoading} = useMarkFirstInstallLayout({
    onSuccess: () => {
      dispatch(doFirstSetupWidgetSuccess());
    }
  })
  const handleDoneInstall = () => {
    markFirstInstallLayout();
  }

  const {
    handleCreateSetupTheme,
    isCreateSetupThemeLoading
  } = useHandleCreateSetupTheme(form);

  const handleSetupDone = () => {
    dispatch(firstSetupDone());
  };

  const waitingLayout = (
    <>
      <h1 className="gx-text-center">Welcome to Flash Search</h1>
      {/* <Slide image={image} afterChange={handleSlider}/> */}
      <Row className="gx-justify-content-center">
        <Col xl={10} lg={10} md={10} sm={24} xs={24}>
          <img alt="" src={iiFast} />
        </Col>
      </Row>
      <div className="gx-pt-5 gx-px-12 gx-text-center">
        <p>Please wait some minutes for indexing your store ...</p>
        <div class="gx-pb-5">
          <PropagateLoader size={20} color="#00ACD7"/>
        </div>
      </div>
    </>
  );

  const indexCompletedLayout = (
    <>
      <Row className="gx-justify-content-center">
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <img alt="" src={iiSearch} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="gx-text-center">
            <div className="gx-mt-6">
              <h2 className="gx-my-4">Index completed!</h2>
              <p>
                Now go ahead and install the search and filter layout to your
                theme.
              </p>
              <Button
                data-testid="setup-widget-button"
                type="primary"
                onClick={onClickSetupWidget}
              >
                Install layout
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );

  const setupWidgetLayout = (
    <Row>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <h1 className="gx-text-center gx-mb-4">Install Flash Search layout</h1>
        <SetupThemeForm
          form={form}
          formItemLayout={formItemLayout}
          handleSetup={handleSetupWidget}
          setupBtnLoading={item2Loading}
          handleCreateSetupTheme={handleCreateSetupTheme}
          isCreateSetupThemeLoading={isCreateSetupThemeLoading}
          handleDoneInstall={handleDoneInstall}
          isDoneInstallLoading={isDoneInstallLoading}
          disableCancel={true}
        />
      </Col>
    </Row>
  );

  const setupCompletedLayout = (
    <>
      <Row className="gx-justify-content-center">
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <img alt="" src={iiSearch} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="gx-text-center">
            <div className="gx-mt-6">
              <h2 className="gx-my-4">Setup completed!</h2>
              <p>Now you can go to your store and experience new searching.</p>
              <p>Go to the app’s homepage to use some cool features.</p>
              <Button
                data-testid="done-setup-button"
                type="primary"
                loading={itemLoading}
                onClick={handleSetupDone}
              >
                Done
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );

  return firstSetup ? null : (
    <Modal
      visible={true}
      className="gx-modal-welcome"
      footer={null}
      closable={false}
      onCancel={handleDone}
    >
      {firstIndexing
        ? firstSetupWidget
          ? setupCompletedLayout
          : showSetupWidget
          ? setupWidgetLayout
          : indexCompletedLayout
        : waitingLayout}
    </Modal>
  );
  //
  // const waitingLayout = (
  //   <Modal
  //     visible={true}
  //     className="gx-modal-welcome"
  //     footer={null}
  //     closable={false}
  //     onCancel={handleDone}
  //   >
  //     <h1 className="gx-text-center">Welcome to Flash Search</h1>
  //     <Slide image={image} afterChange={handleSlider}/>
  //     <div className="gx-pt-12 gx-px-12 gx-text-center">
  //       <p>Please wait while we are indexing your store. It may take some minutes to
  //         complete.</p>
  //       <ProgressMemo percent={progress}/>
  //       {/*<Progress percent={progress}/>*/}
  //     </div>
  //   </Modal>
  // );

  // const doneLayout = (
  //   <Modal
  //     visible={true}
  //     className="gx-modal-welcome"
  //     footer={null}
  //     closable={false}
  //     onCancel={handleDone}
  //   >
  //     <Row className="gx-justify-content-center">
  //       <Col xl={8} lg={8} md={8} sm={24} xs={24}>
  //         <img alt="" src={require("assets/images/common/fs-ii-search.svg")}/>
  //       </Col>
  //     </Row>
  //     <Row>
  //       <Col span={24}>
  //         <div className="gx-text-center">
  //           <div className="gx-mt-6">
  //             <h2 className="gx-my-4">Index complete!</h2>
  //             <p>Now go ahead and setup your widget.</p>
  //             <Button type="primary" onClick={handleDone}>Setup widget</Button>
  //           </div>
  //         </div>
  //       </Col>
  //     </Row>
  //   </Modal>
  // );

  // return (
  //   <>
  //     {showWaiting ? waitingLayout : null}
  //     {showDone ? doneLayout : null}
  //   </>
  // );
};

const WrapperWelcomeForm = Form.create({ name: "WelcomeForm" })(Welcome);

export default WrapperWelcomeForm;
