import React from "react";
import PropTypes from "prop-types";

const CustomLink = ({ children, href, type, className, ...res }) => {
  return (
    <a
      className={
        "gx-link gx-btn-link" +
        (type === "normal" ? " gx-text-color-normal" : "") +
        (className ? " " + className : "") +
        " gx-custom-link"
      }
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      style={{ fontSize: "unset" }}
      {...res}
    >
      {children}
    </a>
  );
};

export default CustomLink;

CustomLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "normal"])
};

CustomLink.defaultProps = {
  type: "primary"
};
