import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import useHandleErrorMsg from "./useHandleMsg";

export const useReqQuery = (queryKey, queryFunc, queryConfig) => {
  const query = useQuery(queryKey, queryFunc, queryConfig);
  useHandleErrorMsg(query);
  return query;
}

export const useInfiniteReqQuery = (queryKey, queryFunc, queryConfig) => {
  const query = useInfiniteQuery(queryKey, queryFunc, queryConfig);
  useHandleErrorMsg(query);
  return query;
}

export const useReqMutation = (func, options) => {
  const mutation = useMutation(func, options);
  useHandleErrorMsg(mutation);
  return mutation;
}

export const useReqMutationWithoutHandleErr = (func, options) => {
  const mutation = useMutation(func, options);
  return mutation;
}


