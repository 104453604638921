import {createSlice} from "@reduxjs/toolkit";

const INIT_STATE = {
  syncStarted: false,
  syncSuccess: false,
  cancelSyncSuccess: false,
  syncFailed: false,
  syncType: "",
};

const slice = createSlice({
  name: "sync",
  initialState: INIT_STATE,
  reducers: {
    syncStarted(state, action) {
      return {
        ...state,
        syncStarted: true,
        syncSuccess: false,
        cancelSyncSuccess: false,
        syncFailed: false,
        syncType: action.payload,
      }
    },
    syncEnded(state) {
      state.syncStarted = false;
    },
    syncSuccess(state) {
      return {
        ...state,
        syncSuccess: true,
      }
    },
    syncFailed(state) {
      return {
        ...state,
        syncFailed: true,
      }
    },
    cancelSyncSuccess(state) {
      return {
        ...state,
        cancelSyncSuccess: true,
      }
    },
  },
});

export const {
  syncStarted,
  syncEnded,
  syncSuccess,
  syncFailed,
  cancelSyncSuccess,
} = slice.actions;

export default slice.reducer;
