import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {DEFAULT_LIST_DELIMITER, DEFAULT_WEIGHT, VALUE_TYPE_TEXT} from "../../constants/Settings/SearchFields";
import {updateItemEnableStatus} from "./utils";

const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
  currentEditItem: {
    valueDataType: VALUE_TYPE_TEXT,
    delimiter: DEFAULT_LIST_DELIMITER,
    weight: DEFAULT_WEIGHT,
    searchForProducts: true,
    buildSearchSuggestion: false,
  },
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  offset: 1,
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {
    valueDataType: VALUE_TYPE_TEXT,
    delimiter: DEFAULT_LIST_DELIMITER,
    searchForProducts: true,
    buildSearchSuggestion: false,
  },
};

const INIT_SYNC_BANNER = {
  displayVMfSfSyncBanner: false,
}

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_SYNC_BANNER,
};

const slice = createSlice({
  name: "variantMetafieldSearchField",
  initialState: INIT_STATE,
  reducers: {
    createAVariantMetafieldSearchField() {},

    createAVariantMetafieldSearchFieldSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
      }
    },

    showCreateAVariantMetafieldSearchFieldModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        showCreateAItemModal: true,
      }
    },

    hideCreateAVariantMetafieldSearchFieldModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all metafield search fields
    getAllVariantMetafieldSearchFields: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
        }
        return {
          payload: params
        }
      }
    },

    getAllVariantMetafieldSearchFieldsSuccess: {
      reducer(state, action) {
        const {data} = action.payload;
        const {total, results} = data;

        return {
          ...state,
          items: results,
          totalCurrentItems: total,
          totalItems: total,
        };
      },
      prepare(data) {
        return {payload: {data}}
      }
    },

    // Update a metafield search field
    updateAVariantMetafieldSearchField: {
      reducer(state) {
        return state;
      },
      prepare(searchField, metadata) {
        return {payload: {searchField, metadata}};
      }
    },

    updateAVariantMetafieldSearchFieldSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    showEditAVariantMetafieldSearchFieldModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditAVariantMetafieldSearchFieldModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Update status a item
    updateStatusAVariantMetafieldSearchField() {},

    updateStatusAVariantMetafieldSearchFieldSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    // Delete a item
    deleteAVariantMetafieldSearchField() {},

    // Setup current edit item
    setCurrentEditItem(state, action) {
      state.currentEditItem = action.payload;
    },

    showVMfSfSyncBanner(state) {
      state.displayVMfSfSyncBanner = true;
    },

    hideVMfSfSyncBanner(state) {
      state.displayVMfSfSyncBanner = false;
    },
  }
});

export const {
  createAVariantMetafieldSearchField,
  createAVariantMetafieldSearchFieldSuccess,
  showCreateAVariantMetafieldSearchFieldModal,
  hideCreateAVariantMetafieldSearchFieldModal,
  getAllVariantMetafieldSearchFields,
  getAllVariantMetafieldSearchFieldsSuccess,
  updateAVariantMetafieldSearchField,
  updateAVariantMetafieldSearchFieldSuccess,
  showEditAVariantMetafieldSearchFieldModal,
  hideEditAVariantMetafieldSearchFieldModal,
  updateStatusAVariantMetafieldSearchField,
  updateStatusAVariantMetafieldSearchFieldSuccess,
  deleteAVariantMetafieldSearchField,
  setCurrentEditItem,
  showVMfSfSyncBanner,
  hideVMfSfSyncBanner,
} = slice.actions;

export default slice.reducer;
