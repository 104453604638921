import { useReqQuery } from "../../hooks/query";
import themeAPI from "../../services/theme";

const useCheckIsSupportedTheme = themeId => {
  const query = useReqQuery(["check-is-supported-theme", themeId], async () => {
    const { data } = await themeAPI.checkIsSupportedTheme(themeId);
    return data;
  }, {
    enabled: themeId !== undefined,
  });

  return {
    data: query.data ? query.data : {},
    isLoading: query.isFetching,
    isError: query.isError
  };
};

export default useCheckIsSupportedTheme;
