import { useReqMutation } from "../../../hooks/query";
import themeAPI from "../../../services/theme";

const useMarkFirstInstallLayout = ({ onSuccess }) => {
  const mutation = useReqMutation(
    () => {
      return themeAPI.markFirstInstallLayout();
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      }
    }
  );

  const markFirstInstallLayout = () => mutation.mutate();

  return {
    markFirstInstallLayout,
    isLoading: mutation.isLoading
  };
};

export default useMarkFirstInstallLayout;
