import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import ThemeSettings from "../slices/ThemeSettings";
import Auth from "../slices/Auth";
import Common from "../slices/Common";
import FilterGroup from "../slices/FilterGroup";
import Filter from "../slices/Filter";
import Pin from "../slices/Pin";
import Merchandising from "../slices/Merchandising";
import Synonyms from "../slices/Synonyms";
import Redirect from "../slices/Redirect";
import DefaultSearchField from "../slices/DefaultSearchField";
import MetafieldSearchField from "../slices/MetafieldSearchField";
import VariantMetafieldSearchField from "../slices/VariantMetafieldSearchField";
import ShopifyTheme from "../slices/ShopifyTheme"
import Theme from "../slices/Theme";
import Settings from "../slices/Settings";
import Sync from "../slices/Sync";


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  themeSettings: ThemeSettings,
  auth: Auth,
  common: Common,
  filterGroup: FilterGroup,
  filter: Filter,
  pin: Pin,
  shopifyTheme: ShopifyTheme,
  merchandising: Merchandising,
  synonyms: Synonyms,
  redirect: Redirect,
  defaultSearchField: DefaultSearchField,
  metafieldSearchField: MetafieldSearchField,
  variantMetafieldSearchField: VariantMetafieldSearchField,
  theme: Theme,
  settings: Settings,
  sync: Sync,
});

export default createRootReducer
