import axios from "axios";

import config from "../config";

const THEME_ENDPOINT = `${config.apiEndpoint}/auth/themes`;

const setup = data => {
  const url = `${THEME_ENDPOINT}/setup`;
  return axios({
    url,
    data,
    method: "post",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const revert = data => {
  const url = `${THEME_ENDPOINT}/revert`;
  return axios({
    url,
    data,
    method: "post",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const markFirstInstallLayout = data => {
  const url = `${THEME_ENDPOINT}/markFirstInstallLayout`;
  return axios({
    url,
    data,
    method: "post",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const createOne = data => {
  const url = `${THEME_ENDPOINT}/setupThemes`;
  return axios({
    url,
    data,
    method: "post",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const getAllSetupThemes = () => {
  const url = `${THEME_ENDPOINT}/setupThemes`;
  return axios({
    url,
    method: "get",
    withCredentials: true
  });
};

const getSetupTheme = id => {
  const url = `${THEME_ENDPOINT}/setupThemes/${id}`;
  return axios({
    url,
    method: "get",
    withCredentials: true
  });
};

const updateSetupTheme = data => {
  const { id, ...res } = data;
  const url = `${THEME_ENDPOINT}/setupThemes/${id}`;
  return axios({
    url,
    method: "put",
    data: res,
    withCredentials: true
  });
};

const deleteMany = ids => {
  const url = `${THEME_ENDPOINT}/setupThemes/bulk_delete`;
  return axios({
    url,
    data: { ids },
    method: "post",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const enableIs = data => {
  const url = `${THEME_ENDPOINT}/setupThemes/enableInstantSearch`;
  return axios({
    url,
    method: "post",
    data,
    withCredentials: true
  });
};

const enableFilter = data => {
  const url = `${THEME_ENDPOINT}/setupThemes/enableFilter`;
  return axios({
    url,
    method: "post",
    data,
    withCredentials: true
  });
};

const checkOs2 = themeId => {
  const url = `${THEME_ENDPOINT}/setupThemes/checkStore2`;
  return axios({
    url,
    method: "post",
    data: { id: themeId },
    withCredentials: true
  });
};

const checkIsSupportedTheme = themeId => {
  const url = `${THEME_ENDPOINT}/setupThemes/checkIsSupportedTheme`;
  return axios({
    url,
    method: "post",
    data: { id: themeId },
    withCredentials: true
  });
};

const themeAPI = {
  setup,
  revert,
  markFirstInstallLayout,
  createOne,
  getAllSetupThemes,
  getSetupTheme,
  updateSetupTheme,
  enableIs,
  enableFilter,
  checkOs2,
  checkIsSupportedTheme,
  deleteMany
};

export default themeAPI;
