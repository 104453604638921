import docs from "./docs";

const dotenv = require("dotenv");

// Load environment variables from .env file
dotenv.config();

const config = {
  apiEndpoint:
    process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080/api/v1.0",
  searchApiEndpoint:
    process.env.REACT_APP_SEARCH_API_ENDPOINT ||
    "http://localhost:8081/api/v1.0",
  defaultPageSize: parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE) || 20,
  defaultPageSizeFilter: process.env.REACT_APP_DEFAULT_PAGE_SIZE_FILTER || 30,
  swatchPageSize: parseInt(process.env.REACT_APP_SWATCH_PAGE_SIZE) || 100,
  defaultReportPageSize:
    parseInt(process.env.REACT_APP_DEFAULT_REPORT_PAGE_SIZE) || 100,
  emailContact: "support@flashsearch.io",
  footerText: "Copyright FlashSearch © 2020",
  docs,
  shopifyAppStoreFlashSearchUrl:
    process.env.REACT_APP_SHOPIFY_APP_STORE_FLASH_SEARCH ||
    "https://apps.shopify.com/flash-search"
};

export default config;
