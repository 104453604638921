import React from "react";
import CustomSpin from "../CustomSpin";

const CircularProgress = () => {
  return (
    <div className="loader loading">
      <CustomSpin spinning={true}/>
    </div>
  )
}
export default CircularProgress;
