import axios from "axios";

import config from "../config";
import {encodeQueryData} from "./utils";

const ENDPOINT = config.apiEndpoint;

const createOne = (item) => {
  const url = `${ENDPOINT}/auth/filters`;
  return axios({
    url,
    data: item,
    method: "post",
    withCredentials: true,
  })
};

const getAll = (params) => {
  const url = `${ENDPOINT}/auth/filters?${encodeQueryData(params)}`;
  return axios({
    url,
    method: "get",
    withCredentials: true,
  })
};

const updateOne = (item) => {
  const url = `${ENDPOINT}/auth/filters/${item.id}`;
  return axios({
    url,
    method: "put",
    data: item,
    withCredentials: true,
  })
};

const deleteOne = (id) => {
  const url = `${ENDPOINT}/auth/filters/${id}`;
  return axios({
    url,
    method: 'delete',
    withCredentials: true,
  })
};

const deleteMany = (ids) => {
  const url = `${ENDPOINT}/auth/filters/bulk_delete`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: ids,
    },
    withCredentials: true,
  })
};

const updatePositions = (newOrderedIds) => {
  const url = `${ENDPOINT}/auth/filters/update_positions`;
  return axios({
    url,
    method: 'post',
    data: {
      ids: newOrderedIds,
    },
    withCredentials: true,
  })
};

const filterAPI = {
  createOne,
  getAll,
  updateOne,
  deleteOne,
  deleteMany,
  updatePositions,
};

export default filterAPI;

