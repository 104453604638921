import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import * as serviceWorker from './registerServiceWorker';

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    <Component/>,
    document.getElementById('root')
  );
};

// Do this once
serviceWorker.unregister();

// Render once
render(NextApp);
