import {Skeleton} from "antd";
import React from "react";
import PropTypes from "prop-types";

const SkeletonDisplayText = ({size, loading, children}) => {
  return (
    <Skeleton paragraph={{rows: 1, width: 200, className: size}} title={false} loading={loading} active>
      {children}
    </Skeleton>
  );
};

export default SkeletonDisplayText;

SkeletonDisplayText.defaultProps = {
  size: "small", // large, medium
};

SkeletonDisplayText.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
};
