import { useReqMutation } from "../../../hooks/query";
import themeAPI from "../../../services/theme";

const useCreateSetupTheme = ({onSuccess}) => {
  const mutation = useReqMutation(
    data => {
      return themeAPI.createOne(data);
    },
    {
      onSuccess: (data, variables) => {
        if (onSuccess) {
          onSuccess(data, variables);
        }
      }
    }
  );

  const createSetupTheme = data => mutation.mutate(data);

  return {
    createSetupTheme,
    isLoading: mutation.isLoading
  };
};

export default useCreateSetupTheme;
