import { useEffect } from "react";

import Nprogress from "nprogress";

const LazyLoad = () => {
  useEffect(() => {
    Nprogress.start();

    return () => {
      Nprogress.done();
    };
  });

  return "";
};

export default LazyLoad;
