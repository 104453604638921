import React from "react";
import PropTypes from "prop-types";

const TextSemiBold = ({ children, className }) => {
  return (
    <span
      className={
        `gx-font-weight-semi-bold` + (className ? " " + className : className)
      }
    >
      {children}
    </span>
  );
};

export default TextSemiBold;

TextSemiBold.defaultProps = {
  className: "",
  children: PropTypes.node
};
