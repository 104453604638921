import {createSlice} from "@reduxjs/toolkit";
import {DEFAULT_PAGE_SIZE} from "../../constants/Common";
import {DEFAULT_LIST_DELIMITER, DEFAULT_WEIGHT, VALUE_TYPE_TEXT} from "../../constants/Settings/SearchFields";
import {updateItemEnableStatus} from "./utils";

const INIT_CREATE_A_ITEM= {
  showCreateAItemModal: false,
  currentEditItem: {
    isValueTypeMetaobject: false,
    metaobjectFieldKey: "",
    valueDataType: VALUE_TYPE_TEXT,
    delimiter: DEFAULT_LIST_DELIMITER,
    weight: DEFAULT_WEIGHT,
    searchForProducts: true,
    buildSearchSuggestion: false,
  },
};

const INIT_GET_ALL_ITEMS = {
  items: [],
  totalItems: 0,
  totalCurrentItems: 0,
  offset: 1,
};

const INIT_UPDATE_A_ITEM= {
  showEditAItemModal: false,
  currentEditItem: {
    valueDataType: VALUE_TYPE_TEXT,
    delimiter: DEFAULT_LIST_DELIMITER,
    searchForProducts: true,
    buildSearchSuggestion: false,
  },
};

const INIT_SYNC_BANNER = {
  displayMfSfSyncBanner: false,
}

const INIT_STATE = {
  ...INIT_CREATE_A_ITEM,
  ...INIT_GET_ALL_ITEMS,
  ...INIT_UPDATE_A_ITEM,
  ...INIT_SYNC_BANNER,
};

const slice = createSlice({
  name: "metafieldSearchField",
  initialState: INIT_STATE,
  reducers: {
    // Create a redirect
    createAMetafieldSearchField() {},

    createAMetafieldSearchFieldSuccess(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
      }
    },

    showCreateAMetafieldSearchFieldModal(state) {
      return {
        ...state,
        ...INIT_CREATE_A_ITEM,
        showCreateAItemModal: true,
      }
    },

    hideCreateAMetafieldSearchFieldModal(state) {
      state.showCreateAItemModal = false;
    },

    // Get all metafield search fields
    getAllMetafieldSearchFields: {
      reducer(state, action) {
        state.offset = action.payload.offset;
      },
      prepare(params) {
        if (params === undefined) {
          params = {offset: 1, limit: DEFAULT_PAGE_SIZE, search: ''};
        } else {
          params.offset = params.offset || 1;
          params.limit = params.limit || DEFAULT_PAGE_SIZE;
        }
        return {
          payload: params
        }
      }
    },

    getAllMetafieldSearchFieldsSuccess: {
      reducer(state, action) {
        const {data} = action.payload;
        const {total, results} = data;

        return {
          ...state,
          items: results,
          totalCurrentItems: total,
          totalItems: total,
        };
      },
      prepare(data) {
        return {payload: {data}}
      }
    },

    // Update a metafield search field
    updateAMetafieldSearchField: {
      reducer(state) {
        return state;
      },
      prepare(searchField, metadata) {
        return {payload: {searchField, metadata}};
      }
    },

    updateAMetafieldSearchFieldSuccess(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    showEditAMetafieldSearchFieldModal(state, action) {
      return {
        ...state,
        currentEditItem: {...action.payload},
        showEditAItemModal: true,
      }
    },

    hideEditAMetafieldSearchFieldModal(state) {
      return {
        ...state,
        ...INIT_UPDATE_A_ITEM,
      }
    },

    // Update status a item
    updateStatusAMetafieldSearchField() {},

    updateStatusAMetafieldSearchFieldSuccess(state, action) {
      state.items = updateItemEnableStatus(action.payload, state.items);
    },

    // Delete a item
    deleteAMetafieldSearchField() {},

    // Setup current edit item
    setCurrentEditItem(state, action) {
      state.currentEditItem = action.payload;
    },

    showMfSfSyncBanner(state) {
      state.displayMfSfSyncBanner = true;
    },

    hideMfSfSyncBanner(state) {
      state.displayMfSfSyncBanner = false;
    },
  }
});

export const {
  createAMetafieldSearchField,
  createAMetafieldSearchFieldSuccess,
  showCreateAMetafieldSearchFieldModal,
  hideCreateAMetafieldSearchFieldModal,
  getAllMetafieldSearchFields,
  getAllMetafieldSearchFieldsSuccess,
  updateAMetafieldSearchField,
  updateAMetafieldSearchFieldSuccess,
  showEditAMetafieldSearchFieldModal,
  hideEditAMetafieldSearchFieldModal,
  updateStatusAMetafieldSearchField,
  updateStatusAMetafieldSearchFieldSuccess,
  deleteAMetafieldSearchField,
  setCurrentEditItem,
  showMfSfSyncBanner,
  hideMfSfSyncBanner,
} = slice.actions;

export default slice.reducer;
